import React from 'react';

type LogoProps = {
  width: number;
  height: number;
};

function LogoIcon({ width, height }: LogoProps) {
  return (
    <svg width={width} height={height} viewBox="0 0 71 71" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g>
        <path
          d="M2.79925 23.6866H2.73969C1.01249 26.8329 0 30.3948 0 34.1941C0 37.8747 0.893378 41.3178 2.50146 44.3454C2.68013 44.3454 2.79925 44.4048 2.97793 44.4048C13.9367 44.4048 24.1212 47.4917 32.8167 52.7752C38.2961 56.0996 43.2395 60.3739 47.2895 65.3011C47.349 65.3011 47.2895 65.3011 47.349 65.3011C47.349 57.3463 45.0858 49.8663 41.2145 43.5737C33.9483 31.6414 20.7859 23.6272 5.71762 23.6272C4.70512 23.6272 3.75219 23.6272 2.79925 23.6866Z"
          fill="#2A6E99"
        />
        <path
          d="M5.71764 23.6272C10.8992 27.9608 15.3661 33.1255 18.761 39.0026C23.7043 47.4917 26.5631 57.3463 26.5036 67.8538C29.7793 69.8128 33.591 71.0001 37.7006 71.0001C41.0954 71.0001 44.3116 70.2284 47.1704 68.863C47.2299 68.6849 47.2895 68.4474 47.2895 68.2693C47.349 67.3195 47.4086 66.3697 47.4086 65.3605C47.4682 50.5193 39.666 37.4591 27.9925 30.0979C21.5602 26.0018 13.9367 23.6272 5.71764 23.6272Z"
          fill="url(#paint0_linear_2_14)"
        />
        <path
          d="M68.4328 47.3136H68.4923C70.2195 44.2267 71.232 40.6054 71.232 36.8061C71.232 33.1255 70.3386 29.6823 68.7305 26.6548C68.5519 26.6548 68.4328 26.5954 68.2541 26.5954C57.2953 26.5954 47.1108 23.5084 38.3557 18.225C32.8763 14.9006 27.9925 10.6263 23.883 5.69907C23.8234 5.69907 23.883 5.69907 23.8234 5.69907C23.8234 13.6539 26.0866 21.1338 29.958 27.4859C37.2836 39.4181 50.5056 47.373 65.5739 47.4324C66.5269 47.4324 67.4798 47.373 68.4328 47.3136Z"
          fill="#9E1C27"
        />
        <path
          d="M65.5739 47.4323C60.3328 43.0987 55.9254 37.8746 52.5306 32.0569C47.5277 23.5084 44.7284 13.6538 44.7284 3.14632C41.4527 1.12793 37.5814 0 33.4719 0C30.077 0 26.8609 0.771739 24.0021 2.13712C23.9425 2.31522 23.8829 2.55268 23.8829 2.73077C23.8829 3.6806 23.8234 4.6898 23.8234 5.63963C23.7638 20.4808 31.566 33.541 43.2395 40.9615C49.6718 44.9983 57.3548 47.4323 65.5739 47.4323Z"
          fill="url(#paint1_linear_2_14)"
        />
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_2_14"
          x1="26.5594"
          y1="73.1483"
          x2="26.5585"
          y2="22.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48B3D9" />
          <stop offset="0.3847" stopColor="#47B1D8" />
          <stop offset="0.5543" stopColor="#45ACD2" />
          <stop offset="0.6816" stopColor="#42A3CA" />
          <stop offset="0.7879" stopColor="#3C96BE" />
          <stop offset="0.8809" stopColor="#3687B0" />
          <stop offset="0.9634" stopColor="#2E76A0" />
          <stop offset="1" stopColor="#2A6E99" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_14"
          x1="21.9287"
          y1="23.7156"
          x2="66.8165"
          y2="23.7149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D81F26" />
          <stop offset="0.4604" stopColor="#D51F26" />
          <stop offset="0.6634" stopColor="#CC1F27" />
          <stop offset="0.8158" stopColor="#BE1F27" />
          <stop offset="0.9422" stopColor="#AA1D27" />
          <stop offset="1" stopColor="#9E1C27" />
        </linearGradient>
        <clipPath id="clip0_2_14">
          <rect width="286" height="71" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoIcon;
