import React from 'react';
import { LocalVideoTrack } from 'twilio-video';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import LocalAudioLevelIndicator from '../../../LocalAudioLevelIndicator/LocalAudioLevelIndicator';
import VideoTrack from '../../../VideoTrack/VideoTrack';
import useVideoContext from '../../../../hooks/useVideoContext/useVideoContext';
import AvatarIdentity from '../../../AvatarIdentity/AvatarIdentity';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: 'relative',
      width: '100%',
      height: '100%',
    },
    videoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: theme.colors['neutral-600'],
      aspectRatio: '16 / 9',
      borderRadius: '0.5rem',
    },
    videoActions: {
      position: 'absolute',
      top: '1rem',
      left: '1rem',
    },
  })
);

export default function LocalVideoPreview({ identity }: { identity?: string }) {
  const classes = useStyles();
  const { localTracks } = useVideoContext();

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;

  return (
    <div className={classes.container}>
      <div className={classes.videoContainer}>
        {videoTrack ? <VideoTrack track={videoTrack} isLocal /> : <AvatarIdentity identity={identity || ''} />}
      </div>

      <span className={classes.videoActions}>
        <LocalAudioLevelIndicator />
      </span>
    </div>
  );
}
