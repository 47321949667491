import React, { useState, useEffect } from 'react';
import { DEFAULT_VIDEO_CONSTRAINTS, SELECTED_VIDEO_INPUT_KEY } from '../../../constants';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { LocalVideoTrack, createLocalVideoTrack } from 'twilio-video';
import { makeStyles } from '@material-ui/core/styles';
import VideoTrack from '../../VideoTrack/VideoTrack';
import useDevices from '../../../hooks/useDevices/useDevices';
import useMediaStreamTrack from '../../../hooks/useMediaStreamTrack/useMediaStreamTrack';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import theme from '../../../theme';

const useStyles = makeStyles({
  preview: {
    width: '100%',
    aspectRatio: '16 / 9',
    margin: '0.5em auto',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: theme.colors['neutral-200'],
    borderRadius: '0.5em',
    '& video': {
      borderRadius: '0.5em',
    },
  },
});

export default function VideoInputList() {
  const classes = useStyles();
  const { videoInputDevices } = useDevices();
  const { room, localTracks } = useVideoContext();

  const localVideoTrack = localTracks.find(track => track.kind === 'video') as LocalVideoTrack | undefined;
  const mediaStreamTrack = useMediaStreamTrack(localVideoTrack);
  const [storedLocalVideoDeviceId, setStoredLocalVideoDeviceId] = useState(
    window.localStorage.getItem(SELECTED_VIDEO_INPUT_KEY)
  );
  const localVideoInputDeviceId = mediaStreamTrack?.getSettings().deviceId || storedLocalVideoDeviceId;
  const [isFirstLoad, setIsFirstLoad] = useState(true); // Nuevo estado para controlar la primera carga

  function replaceTrack(newDeviceId: string) {
    setStoredLocalVideoDeviceId(newDeviceId);
    window.localStorage.setItem(SELECTED_VIDEO_INPUT_KEY, newDeviceId);

    localVideoTrack?.restart({
      ...(DEFAULT_VIDEO_CONSTRAINTS as {}),
      deviceId: { exact: newDeviceId },
    });
  }

  useEffect(() => {
    if (isFirstLoad) {
      const selectFrontCamera = () => {
        const frontCamera = videoInputDevices.find(
          device => device.kind === 'videoinput' && device.label.toLowerCase().includes('front')
        );

        if (frontCamera) {
          replaceTrack(frontCamera.deviceId);
        }
      };

      selectFrontCamera();
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, videoInputDevices]);

  return (
    <div>
      <FormControl fullWidth>
        <Typography variant="subtitle2" gutterBottom>
          Dispositivos de video
        </Typography>
        <Select
          onChange={e => replaceTrack(e.target.value as string)}
          value={localVideoInputDeviceId || ''}
          variant="outlined"
        >
          {videoInputDevices.map(device => (
            <MenuItem value={device.deviceId} key={device.deviceId}>
              {device.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {localVideoTrack && (
        <div className={classes.preview}>
          <VideoTrack isLocal track={localVideoTrack} />
        </div>
      )}

      {!localVideoTrack && (
        <div className={classes.preview}>
          <Typography variant="body2">La cámara está desactivada.</Typography>
        </div>
      )}
    </div>
  );
}
