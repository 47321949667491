import React from 'react';
import { CircularProgress, Theme, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
    gap: 20,
    maxWidth: '550px',
    margin: '0 auto',
    padding: '3rem 1rem 1rem',
    textAlign: 'center',
  },
}));

const ScreenErrorRoomCompleted = () => {
  const classes = useStyles();

  return (
    <div className={classes.container} style={{ justifyContent: 'center' }}>
      <CircularProgress size={64} color="primary" />
      <Typography>Finalizando la sesión, por favor espera un momento...</Typography>
    </div>
  );
};

export default ScreenErrorRoomCompleted;
