import React, { useEffect, useState } from 'react';
import Video from 'twilio-video';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Alert } from '@material-ui/lab';

const useStyles = makeStyles((theme: Theme) => ({
  alert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    position: 'fixed',
    left: '50%',
    top: '1em',
    zIndex: 1000,
    width: '100%',
    maxWidth: '500px',
    transform: 'translateX(-50%)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 2em)',
    },
  },
  close: {
    padding: 0,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '1.5rem',
    height: '1.5rem',
    color: 'inherit',
  },
}));

export default function UnsupportedBrowserWarning({ children }: { children: React.ReactElement }) {
  const classes = useStyles();
  const isSupportedBrowser = Video?.isSupported;
  const [isOpened, setIsOpened] = useState(false);

  useEffect(() => {
    if (!isSupportedBrowser) {
      setIsOpened(true);
    }
  }, [isSupportedBrowser]);

  return (
    <>
      {isOpened && (
        <Alert severity="info" className={classes.alert} onClose={() => setIsOpened(false)}>
          Para una mejor experiencia, te recomendamos abrir el link de la consulta en uno de los siguientes navegadores:
          Chrome, Firefox, Safari o Edge.
        </Alert>
      )}

      {children}
    </>
  );
}
