import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScreencast } from '@fortawesome/pro-light-svg-icons';

import Tooltip from '@material-ui/core/Tooltip';
import useScreenShareParticipant from '../../../hooks/useScreenShareParticipant/useScreenShareParticipant';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { Fab } from '@material-ui/core';

export const SCREEN_SHARE_TEXT = 'Compartir pantalla';
export const STOP_SCREEN_SHARE_TEXT = 'Dejar de compartir pantalla';
export const SHARE_IN_PROGRESS_TEXT = 'No se puede compartir la pantalla cuando otro usuario está compartiendo';
export const SHARE_NOT_SUPPORTED_TEXT = 'Compartir pantalla no es compatible con este navegador';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
      '&:disabled': {
        background: theme.colors['neutral-500'],
        color: 'white',
        opacity: 0.4,
      },
    },
  })
);

export default function ToggleScreenShareButton(props: { disabled?: boolean }) {
  const classes = useStyles();
  const screenShareParticipant = useScreenShareParticipant();
  const { toggleScreenShare } = useVideoContext();
  const disableScreenShareButton = Boolean(screenShareParticipant);
  const isScreenShareSupported = navigator.mediaDevices && navigator.mediaDevices.getDisplayMedia;
  const isDisabled = props.disabled || disableScreenShareButton || !isScreenShareSupported;

  let tooltipMessage = SCREEN_SHARE_TEXT;

  if (disableScreenShareButton) {
    tooltipMessage = SHARE_IN_PROGRESS_TEXT;
  }

  if (!isScreenShareSupported) {
    tooltipMessage = SHARE_NOT_SUPPORTED_TEXT;
  }

  return (
    <Tooltip
      title={tooltipMessage}
      placement="top"
      PopperProps={{ disablePortal: true }}
      style={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
    >
      <span>
        <Fab
          size="medium"
          className={classes.button}
          onClick={toggleScreenShare}
          disabled={isDisabled}
          data-cy-share-screen
        >
          <FontAwesomeIcon size="xl" icon={faScreencast} />
        </Fab>
      </span>
    </Tooltip>
  );
}
