import React, { useState, useEffect } from 'react';
import { Avatar, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';

interface AvatarIdentityProps {
  identity: string;
  className?: string;
}

const useStyles = makeStyles((theme: Theme) => ({
  avatar: {
    width: 96,
    height: 96,
    textTransform: 'uppercase',
    fontSize: 48,
    color: 'white',
    backgroundColor: theme.colors['primary-900'],
  },
}));

function AvatarIdentity({ identity, className }: AvatarIdentityProps) {
  const classes = useStyles();
  const [name, setName] = useState<string | undefined>('');

  useEffect(() => {
    setName(identity.at(0));
  }, [identity]);

  return (
    <Avatar alt={identity} className={clsx(classes.avatar, className)}>
      {name}
    </Avatar>
  );
}

export default AvatarIdentity;
