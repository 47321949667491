import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import LogoFull from './LogoFull';
import LogoIcon from './LogoIcon';

const sizes: Record<string, number> = {
  small: 24,
  medium: 48,
  large: 78,
};

type LogoProps = {
  size?: 'small' | 'medium' | 'large';
  full?: boolean;
  dark?: boolean;
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    dark: {
      color: '#fff',
    },
    light: {
      color: '#4F4F4F',
    },
  })
);

function Logo({ size, full, dark }: LogoProps) {
  const classes = useStyles();
  const height = sizes[size || 'medium'];

  if (!full) return <LogoIcon height={height} width={height * 1} />;

  return <LogoFull className={dark ? classes.dark : classes.light} height={height} width={height * 4} />;
}

export default Logo;
