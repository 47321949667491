import React, { useEffect, useState } from 'react';

import { Widget } from '@typeform/embed-react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import meetingService from '../../services/meeting.service';
import { IconButton, Typography } from '@material-ui/core';
import useMeetingParams from '../../hooks/useMeeting/useMeetingParams';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      '& .tf-v1-widget': {
        height: '600px !important',
      },
    },
    title: {
      fontSize: '1rem',
      fontWeight: 600,
      display: 'block',
      width: 'calc(100% - 30px)',
    },
  })
);

function SurveySatisfaction() {
  const classes = useStyles();
  const { companyId } = useMeetingParams();
  const [open, setOpen] = React.useState(true);
  const [surveySatisfactionURL, setSurveySatisfactionURL] = useState('');

  function onClose(_event: object, reason: string) {
    if (reason === 'backdropClick') return;
    setOpen(false);
  }

  const getUrlSurveySatisfaction = () => {
    if (!companyId) return;

    meetingService.getUrlSurveySatisfaction(companyId).then(url => setSurveySatisfactionURL(url));
  };

  useEffect(() => {
    getUrlSurveySatisfaction();
  }, []);

  if (!surveySatisfactionURL) return null;

  return (
    <Dialog open={open} onClose={onClose} fullWidth={true}>
      <DialogTitle>
        <Typography variant="h1" className={classes.title}>
          Te invitamos a contarnos sobre tu experiencia a través de la siguiente encuesta
        </Typography>
        <IconButton
          size="small"
          aria-label="close"
          onClick={event => onClose(event, 'closeButton')}
          style={{
            position: 'absolute',
            right: 16,
            top: 28,
            width: 40,
            height: 40,
          }}
        >
          <FontAwesomeIcon size="xl" icon={faTimes} />
        </IconButton>
      </DialogTitle>

      <DialogContent className={classes.content}>
        <Widget id={surveySatisfactionURL} />
      </DialogContent>
    </Dialog>
  );
}

export default SurveySatisfaction;
