import React, { useEffect, useState } from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { Skeleton } from '@material-ui/lab';
import { Avatar, Grid, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'grid',
      gridTemplateColumns: 'repeat(4, 1fr)',
      gap: '0.5rem',
      textAlign: 'center',
    },
    missing: {
      width: '4rem',
      height: '4rem',
      marginBottom: '0.25rem',
      background: theme.colors['primary-900'],
    },
  })
);

type WaitingCounterProps = {
  startsAt?: Date;
  isFetching?: boolean;
};

function WaitingCounter({ startsAt, isFetching }: WaitingCounterProps) {
  const classes = useStyles();
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [days, setDays] = useState('00');
  const [hours, setHours] = useState('00');
  const [minutes, setMinutes] = useState('00');
  const [seconds, setSeconds] = useState('00');

  const getMissing = () => {
    const now = new Date();
    const diff = startsAt!.getTime() - now.getTime();
    const dd = Math.floor(diff / (1000 * 3600 * 24));
    const hh = Math.floor((diff % (1000 * 3600 * 24)) / (1000 * 3600));
    const mm = Math.floor((diff % (1000 * 3600)) / (1000 * 60));
    const ss = Math.floor((diff % (1000 * 60)) / 1000);

    if (diff < 0) return;

    const daysString = dd < 10 ? `0${dd}` : dd.toString();
    const hoursString = hh < 10 ? `0${hh}` : hh.toString();
    const minutesString = mm < 10 ? `0${mm}` : mm.toString();
    const secondsString = ss < 10 ? `0${ss}` : ss.toString();

    setDays(daysString);
    setHours(hoursString);
    setMinutes(minutesString);
    setSeconds(secondsString);
  };

  const DisplayTime = ({ time, missing }: { time: string; missing: string }) => {
    if (isFetching)
      return (
        <Grid container direction="column" alignItems="center">
          <Skeleton variant="circle" width={64} height={64} />
          <Skeleton variant="text" width={80} />
        </Grid>
      );

    return (
      <Grid container direction="column" alignItems="center">
        <Avatar className={classes.missing}>{missing}</Avatar>

        <Typography variant="body2">{time}(s)</Typography>
      </Grid>
    );
  };

  useEffect(() => {
    if (!startsAt) return;

    getMissing();

    const t = global.setInterval(getMissing, 1000);
    setTimer(t);

    return () => {
      if (timer) global.clearInterval(timer);
    };
  }, [startsAt]);

  return (
    <div className={classes.container}>
      <DisplayTime time="Día" missing={days} />
      <DisplayTime time="Hora" missing={hours} />
      <DisplayTime time="Minuto" missing={minutes} />
      <DisplayTime time="Segundo" missing={seconds} />
    </div>
  );
}

export default WaitingCounter;
