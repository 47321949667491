import React from 'react';

type LogoProps = {
  width: number;
  height: number;
  className: string;
};

function LogoFull({ width, height, className }: LogoProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 286 71"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g>
        <path
          d="M2.79925 23.6866H2.73969C1.01249 26.8329 0 30.3948 0 34.1941C0 37.8747 0.893378 41.3178 2.50146 44.3454C2.68013 44.3454 2.79925 44.4048 2.97793 44.4048C13.9367 44.4048 24.1212 47.4917 32.8167 52.7752C38.2961 56.0996 43.2395 60.3739 47.2895 65.3011C47.349 65.3011 47.2895 65.3011 47.349 65.3011C47.349 57.3463 45.0858 49.8663 41.2145 43.5737C33.9483 31.6414 20.7859 23.6272 5.71762 23.6272C4.70512 23.6272 3.75219 23.6272 2.79925 23.6866Z"
          fill="#2A6E99"
        />
        <path
          d="M5.71764 23.6272C10.8992 27.9608 15.3661 33.1255 18.761 39.0026C23.7043 47.4917 26.5631 57.3463 26.5036 67.8538C29.7793 69.8128 33.591 71.0001 37.7006 71.0001C41.0954 71.0001 44.3116 70.2284 47.1704 68.863C47.2299 68.6849 47.2895 68.4474 47.2895 68.2693C47.349 67.3195 47.4086 66.3697 47.4086 65.3605C47.4682 50.5193 39.666 37.4591 27.9925 30.0979C21.5602 26.0018 13.9367 23.6272 5.71764 23.6272Z"
          fill="url(#paint0_linear_2_14)"
        />
        <path
          d="M68.4328 47.3136H68.4923C70.2195 44.2267 71.232 40.6054 71.232 36.8061C71.232 33.1255 70.3386 29.6823 68.7305 26.6548C68.5519 26.6548 68.4328 26.5954 68.2541 26.5954C57.2953 26.5954 47.1108 23.5084 38.3557 18.225C32.8763 14.9006 27.9925 10.6263 23.883 5.69907C23.8234 5.69907 23.883 5.69907 23.8234 5.69907C23.8234 13.6539 26.0866 21.1338 29.958 27.4859C37.2836 39.4181 50.5056 47.373 65.5739 47.4324C66.5269 47.4324 67.4798 47.373 68.4328 47.3136Z"
          fill="#9E1C27"
        />
        <path
          d="M65.5739 47.4323C60.3328 43.0987 55.9254 37.8746 52.5306 32.0569C47.5277 23.5084 44.7284 13.6538 44.7284 3.14632C41.4527 1.12793 37.5814 0 33.4719 0C30.077 0 26.8609 0.771739 24.0021 2.13712C23.9425 2.31522 23.8829 2.55268 23.8829 2.73077C23.8829 3.6806 23.8234 4.6898 23.8234 5.63963C23.7638 20.4808 31.566 33.541 43.2395 40.9615C49.6718 44.9983 57.3548 47.4323 65.5739 47.4323Z"
          fill="url(#paint1_linear_2_14)"
        />
      </g>

      <g>
        <path
          d="M85.6452 44.5233C83.8584 43.989 82.4886 43.336 81.4761 42.5642L83.2628 39.1805C84.2753 39.8928 85.5261 40.4865 87.015 40.9614C88.504 41.3769 89.993 41.6144 91.4819 41.6144C95.2341 41.6144 97.0804 40.5458 97.0804 38.4087C97.0804 37.6963 96.8422 37.1621 96.3062 36.7465C95.8297 36.331 95.1746 36.0341 94.4003 35.856C93.626 35.6779 92.554 35.4405 91.1841 35.203C89.2783 34.9062 87.7297 34.55 86.5386 34.1938C85.3474 33.8376 84.3349 33.1846 83.4415 32.2348C82.6077 31.3443 82.1312 30.0383 82.1312 28.3761C82.1312 26.239 83.0246 24.5768 84.8114 23.2708C86.5981 21.9647 88.9805 21.3711 91.9584 21.3711C93.5069 21.3711 95.115 21.5492 96.6635 21.9647C98.212 22.3209 99.5223 22.8552 100.535 23.5082L98.6885 26.892C96.7826 25.6453 94.5194 25.0517 91.8988 25.0517C90.1121 25.0517 88.7422 25.3485 87.7893 25.9422C86.8364 26.5358 86.3599 27.3075 86.3599 28.2574C86.3599 29.0291 86.5981 29.6228 87.1342 30.0977C87.6702 30.5132 88.3253 30.8694 89.0996 31.0475C89.8738 31.2256 91.0055 31.4631 92.4349 31.7599C94.3407 32.0567 95.8297 32.4129 97.0209 32.8285C98.212 33.1846 99.165 33.8376 99.9988 34.7281C100.833 35.6186 101.25 36.8652 101.25 38.4681C101.25 40.6052 100.356 42.2674 98.5098 43.5141C96.6635 44.7607 94.2216 45.4137 91.065 45.4137C89.2783 45.295 87.4319 45.0575 85.6452 44.5233Z"
          fill="currentColor"
        />
        <path
          d="M121.559 23.7459C123.286 25.3487 124.18 27.7233 124.18 30.8696V44.9984H120.13V41.9114C119.415 42.98 118.402 43.8111 117.092 44.4048C115.782 44.9984 114.233 45.2952 112.387 45.2952C109.766 45.2952 107.622 44.6422 106.074 43.3956C104.466 42.1489 103.691 40.4867 103.691 38.4089C103.691 36.3312 104.466 34.669 105.955 33.4223C107.444 32.1757 109.886 31.5227 113.161 31.5227H119.891V30.6915C119.891 28.8512 119.355 27.4859 118.283 26.4767C117.211 25.5268 115.663 24.9926 113.578 24.9926C112.208 24.9926 110.838 25.23 109.469 25.7049C108.158 26.1798 107.027 26.7735 106.074 27.5452L104.287 24.3395C105.478 23.3897 106.967 22.618 108.635 22.0837C110.302 21.5494 112.089 21.312 113.995 21.312C117.33 21.3713 119.832 22.1431 121.559 23.7459ZM117.33 40.9616C118.521 40.2492 119.355 39.24 119.891 37.8747V34.6096H113.34C109.707 34.6096 107.92 35.7969 107.92 38.2308C107.92 39.4181 108.397 40.368 109.29 41.021C110.183 41.674 111.494 42.0302 113.102 42.0302C114.71 42.0302 116.139 41.674 117.33 40.9616Z"
          fill="currentColor"
        />
        <path d="M128.051 12.229H132.339V45.0576H128.051V12.229Z" fill="currentColor" />
        <path
          d="M158.902 21.6088V45.0578H154.852V41.4959C154.018 42.6832 152.887 43.6331 151.457 44.2861C150.028 44.9391 148.539 45.2953 146.871 45.2953C143.715 45.2953 141.273 44.4048 139.486 42.6832C137.699 40.9617 136.806 38.409 136.806 35.0252V21.6088H141.094V34.6097C141.094 36.8655 141.63 38.5871 142.762 39.7744C143.834 40.9617 145.442 41.4959 147.467 41.4959C149.73 41.4959 151.517 40.8429 152.768 39.4776C154.078 38.1122 154.733 36.2125 154.733 33.7192V21.6088H158.902Z"
          fill="currentColor"
        />
        <path
          d="M186.597 12.229V45.0576H182.487V41.3177C181.534 42.6237 180.343 43.5735 178.914 44.2859C177.484 44.9389 175.876 45.2951 174.149 45.2951C171.886 45.2951 169.801 44.8202 168.015 43.811C166.228 42.8018 164.798 41.377 163.786 39.5961C162.774 37.7558 162.237 35.678 162.237 33.3628C162.237 30.9882 162.774 28.9105 163.786 27.1295C164.798 25.3486 166.228 23.9238 168.015 22.9146C169.801 21.9055 171.886 21.4305 174.149 21.4305C175.817 21.4305 177.365 21.7274 178.795 22.3804C180.224 23.0334 181.415 23.9238 182.368 25.1705V12.229H186.597ZM178.556 40.5459C179.748 39.8335 180.701 38.8837 181.415 37.6371C182.07 36.3904 182.428 34.9657 182.428 33.3034C182.428 31.7006 182.07 30.2165 181.415 28.9698C180.76 27.7232 179.807 26.7733 178.556 26.061C177.365 25.408 175.995 25.0518 174.507 25.0518C173.018 25.0518 171.648 25.408 170.457 26.061C169.265 26.714 168.312 27.7232 167.598 28.9698C166.883 30.2165 166.526 31.6412 166.526 33.3034C166.526 34.9063 166.883 36.3904 167.598 37.6371C168.312 38.8837 169.265 39.8929 170.457 40.5459C171.648 41.2583 173.018 41.6145 174.507 41.6145C175.995 41.5551 177.306 41.2583 178.556 40.5459Z"
          fill="currentColor"
        />
        <path
          d="M205.894 43.6331C205.298 44.1674 204.524 44.5829 203.571 44.8204C202.678 45.1172 201.725 45.2359 200.712 45.2359C198.33 45.2359 196.484 44.5829 195.233 43.3363C193.923 42.0896 193.267 40.2493 193.267 37.8747V25.1113H189.277V21.6088H193.267V16.5035H197.556V21.6088H204.286V25.1113H197.556V37.8154C197.556 39.062 197.853 40.0712 198.509 40.7242C199.164 41.3772 200.057 41.7334 201.248 41.7334C202.559 41.7334 203.631 41.3772 204.584 40.6055L205.894 43.6331Z"
          fill="currentColor"
        />
        <path
          d="M211.969 43.7517C210.123 42.7425 208.634 41.3178 207.621 39.4775C206.549 37.6372 206.073 35.6188 206.073 33.3036C206.073 30.9884 206.609 28.97 207.621 27.1297C208.693 25.2894 210.123 23.924 211.969 22.8554C213.815 21.8462 215.959 21.312 218.282 21.312C220.605 21.312 222.689 21.8462 224.536 22.8554C226.382 23.8646 227.811 25.2894 228.883 27.1297C229.955 28.97 230.432 30.9884 230.432 33.3036C230.432 35.6188 229.896 37.6372 228.883 39.4775C227.811 41.3178 226.382 42.7425 224.536 43.7517C222.689 44.7609 220.605 45.2952 218.282 45.2952C215.959 45.2952 213.815 44.7609 211.969 43.7517ZM222.332 40.5461C223.523 39.8337 224.476 38.8839 225.131 37.6372C225.786 36.3905 226.144 34.9658 226.144 33.3036C226.144 31.7007 225.786 30.2166 225.131 28.97C224.476 27.7233 223.523 26.7735 222.332 26.0611C221.141 25.4081 219.771 25.0519 218.282 25.0519C216.793 25.0519 215.423 25.4081 214.232 26.0611C213.041 26.7141 212.088 27.7233 211.373 28.97C210.659 30.2166 210.301 31.6414 210.301 33.3036C210.301 34.9064 210.659 36.3905 211.373 37.6372C212.088 38.8839 213.041 39.8931 214.232 40.5461C215.423 41.2584 216.793 41.6146 218.282 41.6146C219.771 41.5553 221.141 41.2584 222.332 40.5461Z"
          fill="currentColor"
        />
        <path
          d="M238.234 43.7517C236.388 42.7425 234.899 41.3178 233.886 39.4775C232.814 37.6372 232.338 35.6188 232.338 33.3036C232.338 30.9884 232.874 28.97 233.886 27.1297C234.958 25.2894 236.388 23.924 238.234 22.8554C240.08 21.8462 242.224 21.312 244.547 21.312C246.87 21.312 248.955 21.8462 250.801 22.8554C252.647 23.8646 254.077 25.2894 255.149 27.1297C256.221 28.97 256.697 30.9884 256.697 33.3036C256.697 35.6188 256.161 37.6372 255.149 39.4775C254.077 41.3178 252.647 42.7425 250.801 43.7517C248.955 44.7609 246.87 45.2952 244.547 45.2952C242.224 45.2952 240.08 44.7609 238.234 43.7517ZM248.597 40.5461C249.788 39.8337 250.741 38.8839 251.396 37.6372C252.052 36.3905 252.409 34.9658 252.409 33.3036C252.409 31.7007 252.052 30.2166 251.396 28.97C250.741 27.7233 249.788 26.7735 248.597 26.0611C247.406 25.4081 246.036 25.0519 244.547 25.0519C243.058 25.0519 241.688 25.4081 240.497 26.0611C239.306 26.7141 238.353 27.7233 237.638 28.97C236.924 30.2166 236.566 31.6414 236.566 33.3036C236.566 34.9064 236.924 36.3905 237.638 37.6372C238.353 38.8839 239.306 39.8931 240.497 40.5461C241.688 41.2584 243.058 41.6146 244.547 41.6146C246.036 41.5553 247.406 41.2584 248.597 40.5461Z"
          fill="currentColor"
        />
        <path d="M259.08 12.229H263.368V45.0576H259.08V12.229Z" fill="currentColor" />
        <path
          d="M270.277 44.5233C268.49 43.989 267.12 43.336 266.107 42.5642L267.894 39.1805C268.907 39.8928 270.157 40.4865 271.646 40.9614C273.135 41.3769 274.624 41.6144 276.113 41.6144C279.866 41.6144 281.712 40.5458 281.712 38.4087C281.712 37.6963 281.474 37.1621 280.938 36.7465C280.461 36.331 279.806 36.0341 279.032 35.856C278.257 35.6779 277.185 35.4405 275.816 35.203C273.91 34.9062 272.361 34.55 271.17 34.1938C269.979 33.8376 268.966 33.1846 268.073 32.2348C267.239 31.3443 266.763 30.0383 266.763 28.3761C266.763 26.239 267.656 24.5768 269.443 23.2708C271.229 21.9647 273.612 21.3711 276.59 21.3711C278.138 21.3711 279.746 21.5492 281.295 21.9647C282.843 22.3209 284.154 22.8552 285.166 23.5082L283.32 26.892C281.414 25.6453 279.151 25.0517 276.53 25.0517C274.743 25.0517 273.374 25.3485 272.421 25.9422C271.468 26.5358 270.991 27.3075 270.991 28.2574C270.991 29.0291 271.229 29.6228 271.766 30.0977C272.302 30.5132 272.957 30.8694 273.731 31.0475C274.505 31.2256 275.637 31.4631 277.066 31.7599C278.972 32.0567 280.461 32.4129 281.652 32.8285C282.843 33.1846 283.796 33.8376 284.63 34.7281C285.464 35.6186 285.881 36.8652 285.881 38.4681C285.881 40.6052 284.988 42.2674 283.141 43.5141C281.295 44.7607 278.853 45.4137 275.696 45.4137C273.91 45.295 272.063 45.0575 270.277 44.5233Z"
          fill="currentColor"
        />
      </g>

      <defs>
        <linearGradient
          id="paint0_linear_2_14"
          x1="26.5594"
          y1="73.1483"
          x2="26.5585"
          y2="22.1667"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#48B3D9" />
          <stop offset="0.3847" stopColor="#47B1D8" />
          <stop offset="0.5543" stopColor="#45ACD2" />
          <stop offset="0.6816" stopColor="#42A3CA" />
          <stop offset="0.7879" stopColor="#3C96BE" />
          <stop offset="0.8809" stopColor="#3687B0" />
          <stop offset="0.9634" stopColor="#2E76A0" />
          <stop offset="1" stopColor="#2A6E99" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2_14"
          x1="21.9287"
          y1="23.7156"
          x2="66.8165"
          y2="23.7149"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D81F26" />
          <stop offset="0.4604" stopColor="#D51F26" />
          <stop offset="0.6634" stopColor="#CC1F27" />
          <stop offset="0.8158" stopColor="#BE1F27" />
          <stop offset="0.9422" stopColor="#AA1D27" />
          <stop offset="1" stopColor="#9E1C27" />
        </linearGradient>
        <clipPath id="clip0_2_14">
          <rect width="286" height="71" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default LogoFull;
