import { createContext, useContext, useMemo } from 'react';
import { Role } from '../types';

interface AppRoleContextProviderProps {
  children: React.ReactNode;
}

export interface RolesContextState {
  role: Role;
  isAdmin: boolean;
  isParticipant: boolean;
}

export const RolesContext = createContext({} as RolesContextState);

const AppRoleContextProvider = ({ children }: AppRoleContextProviderProps) => {
  const contextValue = useMemo(() => {
    if (
      /^(https?:\/\/)?(localhost(:\d+)?|saludtools\.((dev|qa)\.)?carecloud\.com\.co)(\/)?$/.test(document.referrer) &&
      !document.referrer.startsWith(window.location.origin)
    ) {
      return {
        role: 'admin',
        isAdmin: true,
        isParticipant: false,
      };
    }

    return {
      role: 'participant',
      isAdmin: false,
      isParticipant: true,
    };
  }, []);

  return (
    <RolesContext.Provider value={(contextValue as unknown) as RolesContextState}>{children}</RolesContext.Provider>
  );
};

export default AppRoleContextProvider;

export function useRole() {
  const context = useContext(RolesContext);
  if (!context) {
    throw new Error('useRole must be used within the AppRoleContextProvider');
  }
  return context;
}
