import React from 'react';

import { useActiveSession } from '../../../hooks/useActiveSession/useActiveSession';
import useMeetingState from '../../../hooks/useMeeting/useMeetingState';
import RecoveringSession from '../../MeetingStates/MeetingRecoveringSession/MeetingRecoveringSession';
import MeetingEnded from '../../MeetingStates/MeetingEnded/MeetingEnded';
import WaitingRoom from '../../MeetingStates/MeetingWaitingRoom/MeetingWaitingRoom';
import RoomConnecting from '../../MeetingStates/MeetingRoomConnecting/MeetingRoomConnecting';
import useMeetingParams from '../../../hooks/useMeeting/useMeetingParams';
import useRoomState from '../../../hooks/useRoomState/useRoomState';
import MeetingInvalidUrl from '../../MeetingStates/MeetingInvalidUrl/MeetingInvalidUrl';
import MeetingHaveActive from '../../MeetingStates/MeetingHaveActiveSession/MeetingHaveActiveSession';

interface MeetingConnectorForParticipantProps {
  children: React.ReactNode;
}

const MeetingConnectorForParticipant = ({ children }: MeetingConnectorForParticipantProps) => {
  const roomState = useRoomState();
  const { sessionId, participantId, typeId } = useMeetingParams();
  const { state: meetingState, isFetching } = useMeetingState();
  const { isActiveSession } = useActiveSession();

  const isInvalidUrl = !sessionId || !participantId || !typeId;
  const isNotStarted = !meetingState && !isFetching;
  const isRecoveringSession = !meetingState && isFetching;
  const isWaitingRoom = meetingState === 'WAITING_ROOM' && roomState === 'disconnected';
  const isRoomConnecting = meetingState === 'CALL_IN_PROGRESS' && roomState === 'disconnected';
  const isCallEnded = meetingState === 'CALL_ENDED' && roomState === 'disconnected';
  const isSessionActiveInAnotherTab = !isActiveSession && roomState === 'disconnected';

  if (isInvalidUrl) return <MeetingInvalidUrl />;
  if (isNotStarted) return null;
  if (isRecoveringSession) return <RecoveringSession />;
  if (isSessionActiveInAnotherTab) return <MeetingHaveActive />;
  if (isCallEnded) return <MeetingEnded />;
  if (isWaitingRoom) return <WaitingRoom />;
  if (isRoomConnecting) return <RoomConnecting />;

  return <>{children}</>;
};

export default MeetingConnectorForParticipant;
