import React from 'react';
import { Button, Theme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    textAlign: 'center',
    background: '#fff',
    gap: 20,
    '& img': {
      maxWidth: '600px',
      width: '100%',
    },
    '& p': {
      maxWidth: '760px',
      color: theme.colors['neutral-500'],
      fontSize: '21.88px',
      fontWeight: 500,
    },
    '& button': {
      background: theme.colors['primary-500'],
      textTransform: 'none',
      borderRadius: '8px',
    },
  },
}));

const ScreenErrorRoomLimitFull = () => {
  const classes = useStyles();

  const handleRetry = () => {
    window.location.reload();
  };

  return (
    <div className={classes.container}>
      <img src={require('../../assets/meeting-full.png')} alt="Meeting full" />
      <p>
        ¡Oops! La videoconsulta ha alcanzado su límite de participantes. Por favor intenta nuevamente en 1 minuto, si no
        logras unirte te recomendamos contactar al organizador de la videoconsulta
      </p>
      <Button color="primary" variant="contained" onClick={handleRetry}>
        Volver a unirme
      </Button>
    </div>
  );
};

export default ScreenErrorRoomLimitFull;
