import React, { createContext, ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { CreateLocalTrackOptions, ConnectOptions, LocalAudioTrack, LocalVideoTrack, Room } from 'twilio-video';
import { ErrorCallback } from '../../types';
import { SelectedParticipantProvider } from './useSelectedParticipant/useSelectedParticipant';

import AttachVisibilityHandler from './AttachVisibilityHandler/AttachVisibilityHandler';
import useBackgroundSettings, { BackgroundSettings } from './useBackgroundSettings/useBackgroundSettings';
import useHandleRoomDisconnection from './useHandleRoomDisconnection/useHandleRoomDisconnection';
import useHandleTrackPublicationFailed from './useHandleTrackPublicationFailed/useHandleTrackPublicationFailed';
import useLocalTracks from './useLocalTracks/useLocalTracks';
import useRestartAudioTrackOnDeviceChange from './useRestartAudioTrackOnDeviceChange/useRestartAudioTrackOnDeviceChange';
import useRoom from './useRoom/useRoom';
import useScreenShareToggle from './useScreenShareToggle/useScreenShareToggle';
import { useMessageEventEmitter } from '../../hooks/useMessage/useMessageEventEmitter';
import useMeetingState from '../../hooks/useMeeting/useMeetingState';

export interface IVideoContext {
  room: Room | null;
  localTracks: (LocalAudioTrack | LocalVideoTrack)[];
  isConnecting: boolean;
  connect: (token: string) => Promise<void>;
  onError: ErrorCallback;
  getLocalVideoTrack: (newOptions?: CreateLocalTrackOptions) => Promise<LocalVideoTrack>;
  getLocalAudioTrack: (deviceId?: string) => Promise<LocalAudioTrack>;
  isAcquiringLocalTracks: boolean;
  removeLocalVideoTrack: () => void;
  removeLocalAudioTrack: () => void;
  isSharingScreen: boolean;
  toggleScreenShare: () => void;
  getAudioAndVideoTracks: () => Promise<void>;
  isBackgroundSelectionOpen: boolean;
  openBackgroundSelection: () => void;
  closeBackgroundSelection: () => void;
  toggleBackgroundSelection: () => void;
  setIsBackgroundSelectionOpen: (value: boolean) => void;
  backgroundSettings: BackgroundSettings;
  setBackgroundSettings: (settings: BackgroundSettings) => void;
  isRecording: boolean;
  setIsRecording: (value: boolean) => void;
  state: 'disconnected' | 'connected' | 'reconnecting';
  setIsStartingVideoinputFailed: (value: boolean) => void;
  isStartingVideoinputFailed: boolean;
  isStartingAudioinputFailed: boolean;
  setAudioStream: (value: MediaStream | null) => void;
  setVideoStream: (value: MediaStream | null) => void;
  audioStream: MediaStream | null;
  videoStream: MediaStream | null;
}

export const VideoContext = createContext<IVideoContext>(null!);

interface VideoProviderProps {
  options?: ConnectOptions;
  onError: ErrorCallback;
  children: ReactNode;
}

export function VideoProvider({ options, children, onError = () => {} }: VideoProviderProps) {
  const [state, setState] = useState<IVideoContext['state']>('disconnected');
  const [isStartingVideoinputFailed, setIsStartingVideoinputFailed] = useState(false);
  const [isStartingAudioinputFailed, setIsStartingAudioinputFailed] = useState(false);
  const [audioStream, setAudioStream] = useState<MediaStream | null>(null);
  const [videoStream, setVideoStream] = useState<MediaStream | null>(null);
  const onErrorCallback: ErrorCallback = useCallback(error => onError(error), [onError]);
  const event = useMessageEventEmitter();

  const {
    localTracks,
    getLocalVideoTrack,
    getLocalAudioTrack,
    isAcquiringLocalTracks,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    getAudioAndVideoTracks,
  } = useLocalTracks();
  const { room, isConnecting, connect } = useRoom(localTracks, onErrorCallback, options);

  const [isSharingScreen, toggleScreenShare] = useScreenShareToggle(room, onError);

  useHandleRoomDisconnection(
    room,
    onError,
    removeLocalAudioTrack,
    removeLocalVideoTrack,
    isSharingScreen,
    toggleScreenShare
  );
  useHandleTrackPublicationFailed(room, onError);
  useRestartAudioTrackOnDeviceChange(localTracks);

  const videoTrack = localTracks.find(
    track => !track.name.includes('screen') && track.kind === 'video'
  ) as LocalVideoTrack;
  const [isRecording, setIsRecording] = useState(false);
  const [isBackgroundSelectionOpen, setIsBackgroundSelectionOpen] = useState(false);
  const [backgroundSettings, setBackgroundSettings] = useBackgroundSettings(videoTrack, room);

  const openBackgroundSelection = useCallback(() => {
    setIsBackgroundSelectionOpen(true);
    event.emit('OPEN_MODAL_BACKGROUND_SELECTION');
  }, [event]);

  const closeBackgroundSelection = useCallback(() => {
    setIsBackgroundSelectionOpen(false);
    event.emit('CLOSE_MODAL_BACKGROUND_SELECTION');
  }, [event]);

  const toggleBackgroundSelection = useCallback(() => {
    if (isBackgroundSelectionOpen) {
      closeBackgroundSelection();
    } else {
      openBackgroundSelection();
    }
  }, [closeBackgroundSelection, isBackgroundSelectionOpen, openBackgroundSelection]);

  useEffect(() => {
    if (room) {
      const setRoomState = () => setState(room.state as IVideoContext['state']);
      setRoomState();
      room
        .on('disconnected', setRoomState)
        .on('reconnected', setRoomState)
        .on('reconnecting', setRoomState);
      return () => {
        room
          .off('disconnected', setRoomState)
          .off('reconnected', setRoomState)
          .off('reconnecting', setRoomState);
      };
    }

    if (!navigator?.mediaDevices?.getUserMedia) return;

    let isMounted = true;
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then(stream => {
        if (isMounted) {
          setVideoStream(stream);
        }
      })
      .catch(() => {
        if (isMounted) setIsStartingVideoinputFailed(true);
      });
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then(stream => {
        if (isMounted) {
          setAudioStream(stream);
        }
      })
      .catch(() => {
        if (isMounted) setIsStartingAudioinputFailed(true);
      });
    return () => {
      isMounted = false;
    };
  }, [room, state]);

  const contextValue = useMemo(
    () => ({
      room,
      localTracks,
      isConnecting,
      onError: onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      isSharingScreen,
      toggleScreenShare,
      getAudioAndVideoTracks,
      isBackgroundSelectionOpen,
      openBackgroundSelection,
      closeBackgroundSelection,
      toggleBackgroundSelection,
      setIsBackgroundSelectionOpen,
      backgroundSettings,
      setBackgroundSettings,
      isRecording,
      setIsRecording,
      state,
      setIsStartingVideoinputFailed,
      isStartingVideoinputFailed,
      isStartingAudioinputFailed,
      setAudioStream,
      setVideoStream,
      audioStream,
      videoStream,
    }),
    [
      room,
      localTracks,
      isConnecting,
      onErrorCallback,
      getLocalVideoTrack,
      getLocalAudioTrack,
      connect,
      isAcquiringLocalTracks,
      removeLocalVideoTrack,
      removeLocalAudioTrack,
      isSharingScreen,
      toggleScreenShare,
      getAudioAndVideoTracks,
      isBackgroundSelectionOpen,
      openBackgroundSelection,
      closeBackgroundSelection,
      toggleBackgroundSelection,
      backgroundSettings,
      setBackgroundSettings,
      isRecording,
      state,
      setIsStartingVideoinputFailed,
      isStartingVideoinputFailed,
      isStartingAudioinputFailed,
      setAudioStream,
      setVideoStream,
      audioStream,
      videoStream,
    ]
  );

  return (
    <VideoContext.Provider value={contextValue}>
      <SelectedParticipantProvider room={room}>{children}</SelectedParticipantProvider>
      <AttachVisibilityHandler />
    </VideoContext.Provider>
  );
}
