import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '64px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
      borderTopRightRadius: '8px',
      borderTopLeftRadius: '8px',
    },
    text: {
      fontWeight: 500,
    },
    closeChatWindow: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      border: '0',
      width: '32px',
      height: '32px',
      borderRadius: '100%',
      '&:hover': {
        background: theme.colors['neutral-200'],
      },
    },
  })
);

export default function ChatWindowHeader() {
  const classes = useStyles();
  const { setIsChatWindowOpen } = useChatContext();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Mensajes de la llamada</Typography>
      <button className={classes.closeChatWindow} onClick={() => setIsChatWindowOpen(false)}>
        <FontAwesomeIcon size="xl" icon={faXmark} />
      </button>
    </div>
  );
}
