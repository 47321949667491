import React, { useMemo, useState } from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useAppState } from '../../state';
import { Typography, IconButton, TextField, InputAdornment } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch, faThumbTack, faTimes } from '@fortawesome/pro-light-svg-icons';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import AvatarIdentity from '../AvatarIdentity/AvatarIdentity';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { LocalParticipant, RemoteParticipant } from 'twilio-video';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      background: '#FFFFFF',
      zIndex: 9,
      display: 'grid',
      gridTemplateRows: 'auto 1fr auto',
      borderTopLeftRadius: '8px',
      borderBottomLeftRadius: '8px',
      padding: '1em',
      height: '100%',
      overflow: 'auto',
      '&::-webkit-scrollbar': {
        width: '16px',
        height: '10px',
        backgroundColor: '#D6D9DB',
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#D6D9DB',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#cdcdcd',
      },
      '&::-webkit-scrollbar-thumb:active': {
        background: '#c1bfbf',
      },
      '&::-webkit-scrollbar-track': {
        background: '#F5F5F5',
        borderRadius: '4px',
      },
      '&::-webkit-scrollbar-track:hover': {
        background: 'white',
      },
      '&::-webkit-scrollbar-track:active': {
        background: '#F5F5F5',
      },
      [theme.breakpoints.down('sm')]: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 100,
      },
    },
    hide: {
      display: 'none',
    },
    avatar: {
      width: 40,
      height: 40,
      fontSize: 20,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',

      '& button': {
        minWidth: '40px',
        maxHeight: '40px',
      },

      '& h3': {
        fontSize: '17px',
        fontWeight: 500,
      },
    },
    body: {
      '& > p': {
        fontSize: '14px',
        fontWeight: 600,
        margin: '1em 0',
      },

      '& > ul': {
        listStyle: 'none',
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'column',
        gap: '1em',
      },

      '& > ul > li': {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',

        '& button': {
          minWidth: '40px',
          maxHeight: '40px',
        },
      },

      '& > ul > li > div': {
        display: 'flex',
        gap: '1em',
        alignItems: 'center',
      },
    },
    search: {
      borderRadius: '8px',
      margin: '1em 0',
      width: '100%',
    },
    noResultsMessage: {
      fontSize: '14px',
      fontStyle: 'italic',
      color: 'gray',
    },
  })
);

export default function ParticipantListWindow() {
  const classes = useStyles();
  const [textSearch, setTextSearch] = useState('');
  const { isParticipantListOpen, setIsParticipantListOpen, setIsGalleryViewActive } = useAppState();
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();
  const { room } = useVideoContext();

  const localParticipant = room?.localParticipant;
  const remoteParticipants = useParticipants();

  const participants = useMemo(() => {
    if (!localParticipant) return remoteParticipants;

    return [localParticipant, ...remoteParticipants]
      .filter(participant => {
        if (textSearch === '') return true;

        return participant.identity.toLocaleLowerCase().includes(textSearch.toLocaleLowerCase());
      })
      .sort((a, b) => (a.identity > b.identity ? 1 : -1));
  }, [localParticipant, remoteParticipants, textSearch]);

  const handleClickPinParticipant = (participant: LocalParticipant | RemoteParticipant) => {
    setSelectedParticipant(participant);
    setIsGalleryViewActive(false);
  };

  return (
    <aside className={clsx(classes.container, { [classes.hide]: !isParticipantListOpen })}>
      <header className={classes.header}>
        <Typography variant="h3">Participantes</Typography>
        <IconButton onClick={() => setIsParticipantListOpen(false)}>
          <FontAwesomeIcon icon={faTimes} />
        </IconButton>
      </header>

      <div className={classes.body}>
        <TextField
          placeholder="Buscar participante"
          variant="outlined"
          className={classes.search}
          value={textSearch}
          onChange={e => setTextSearch(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FontAwesomeIcon icon={faSearch} />
              </InputAdornment>
            ),
          }}
        />

        <p>Participantes {participants.length}</p>
        {participants.length === 0 ? (
          <Typography className={classes.noResultsMessage}>No se encontraron coincidencias.</Typography>
        ) : (
          <ul>
            {participants.map(participant => (
              <li key={participant.identity}>
                <div>
                  <AvatarIdentity identity={participant.identity} className={classes.avatar} />
                  <Typography>
                    {participant.identity} {participant === localParticipant && '(Tú)'}
                  </Typography>
                </div>

                <IconButton
                  color={selectedParticipant === participant ? 'primary' : 'default'}
                  onClick={() => handleClickPinParticipant(participant)}
                >
                  <FontAwesomeIcon icon={faThumbTack} />
                </IconButton>
              </li>
            ))}
          </ul>
        )}
      </div>
    </aside>
  );
}
