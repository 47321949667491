import React from 'react';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

function MeetingRecoveringSession() {
  return (
    <Grid container direction="column" alignItems="center" justifyContent="center" style={{ height: '100%', gap: 10 }}>
      <CircularProgress size={64} color="primary" />
      <Typography>
        <span>Recuperando sesión...</span>
      </Typography>
    </Grid>
  );
}

export default MeetingRecoveringSession;
