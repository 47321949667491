import { useLocalStorageState, useSessionStorageState } from 'ahooks';
import { useEffect } from 'react';

export function useActiveSession() {
  const [sessionId, setSessionId] = useSessionStorageState<string | undefined>('session_connection');
  const [activeSession, setActiveSession] = useLocalStorageState<string | undefined>('active_connection');

  const isActiveSession = activeSession === sessionId || !activeSession;

  const connectSession = () => {
    setActiveSession(sessionId);
  };

  const disconnectSession = () => {
    setActiveSession(undefined);
    setSessionId(undefined);
  };

  const setInitialValues = () => {
    const randomUUID = crypto.randomUUID();
    setActiveSession(undefined);
    setSessionId(randomUUID);
  };

  const handleChangeStorage = (event: StorageEvent) => {
    if (event.key !== 'active_connection') return;
    if (event.newValue === sessionId) return;
    if (event.newValue === null) return;

    setTimeout(() => window.twilioRoom?.disconnect());
    setActiveSession(JSON.parse(event.newValue));
  };

  useEffect(() => {
    setInitialValues();

    window.addEventListener('storage', handleChangeStorage);

    return () => {
      window.removeEventListener('storage', handleChangeStorage);
    };
  }, []);

  return {
    isActiveSession,
    connectSession,
    disconnectSession,
  };
}
