import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserGroup } from '@fortawesome/pro-light-svg-icons';

import { createStyles, Fab, makeStyles, Tooltip, Theme } from '@material-ui/core';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
    },
  })
);

export default function ToggleParticipantListButton(props: { className?: string }) {
  const classes = useStyles();
  const { isParticipantListOpen, setIsParticipantListOpen } = useAppState();

  return (
    <Tooltip title="Participantes">
      <Fab
        size="medium"
        className={clsx(classes.button, props.className)}
        onClick={() => setIsParticipantListOpen(!isParticipantListOpen)}
      >
        <FontAwesomeIcon size="xl" icon={faUserGroup} />
      </Fab>
    </Tooltip>
  );
}
