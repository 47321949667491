import React from 'react';
import { makeStyles, createStyles } from '@material-ui/core/styles';

import { Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      minHeight: '64px',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '0 1em',
    },
    text: {
      fontWeight: 500,
    },
    closeBackgroundSelection: {
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'transparent',
      border: '0',
      width: '32px',
      height: '32px',
      borderRadius: '100%',
      '&:hover': {
        background: theme.colors['neutral-200'],
      },
    },
  })
);

interface BackgroundSelectionHeaderProps {
  onClose: () => void;
}

export default function BackgroundSelectionHeader({ onClose }: BackgroundSelectionHeaderProps) {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Typography className={classes.text}>Efectos</Typography>
      <button className={classes.closeBackgroundSelection} onClick={onClose}>
        <FontAwesomeIcon size="xl" icon={faXmark} />
      </button>
    </div>
  );
}
