import React, { useEffect, useState } from 'react';

import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

import { useAppState } from '../../../state';
import { Button, CircularProgress, createStyles, Divider, Fab, makeStyles, Theme, Typography } from '@material-ui/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGear } from '@fortawesome/pro-light-svg-icons';

import LocalVideoPreview from '../../PreJoinScreens/DeviceSelectionScreen/LocalVideoPreview/LocalVideoPreview';
import ToggleAudioButton from '../../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleVideoButton from '../../Buttons/ToggleVideoButton/ToggleVideoButton';
import Logo from '../../Logo/Logo';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import { useMessageEventEmitter } from '../../../hooks/useMessage/useMessageEventEmitter';
import { useActiveSession } from '../../../hooks/useActiveSession/useActiveSession';
import ScreenErrorRoomLimitFull from '../../ScreenError/RoomLimitFull';
import ScreenErrorRoomCompleted from '../../ScreenError/RoomCompleted';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      height: '100%',
      gap: 20,
      maxWidth: '550px',
      margin: '0 auto',
      padding: '3rem 1rem 1rem',
      textAlign: 'center',
    },
    videoContainer: {
      position: 'relative',
      width: '100%',
    },
    videoActions: {
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      padding: 8,
      display: 'flex',
      justifyContent: 'space-between',
    },
    videoControl: {
      display: 'flex',
      gap: 8,
    },
    errorContainer: {
      textAlign: 'center',
    },
    button: {
      borderRadius: '8px',
      background: theme.colors['primary-500'],
      color: 'white',
      '&:hover': {
        background: theme.colors['primary-600'],
      },
    },
    buttonSetting: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
    },
  });
});

export default function MeetingRoomConnecting() {
  const event = useMessageEventEmitter();
  const classes = useStyles();
  const [settingsOpen, setSettingsOpen] = useState(false);
  const { getToken, isFetching, error } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connectSession, disconnectSession } = useActiveSession();
  const {
    connect: videoConnect,
    isConnecting,
    isAcquiringLocalTracks,
    getAudioAndVideoTracks,
    setIsRecording,
  } = useVideoContext();

  const disableButtons = isFetching || isAcquiringLocalTracks || isConnecting;

  const handleJoin = async () => {
    connectSession();

    const { token } = await getToken();
    const isEnableTwilioConversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true';

    token && (await videoConnect(token).then(() => setIsRecording(false)));
    isEnableTwilioConversation && chatConnect(token);
  };

  const LoadingText = () => {
    if (isFetching) return <span>Recuperando configuracion...</span>;
    if (isConnecting) return <span>Conectando a la sala...</span>;

    return <span>Un momento por favor...</span>;
  };

  const showSettings = () => {
    event.emit('OPEN_MODAL_SETTINGS');
    setSettingsOpen(true);
  };

  const hideSettings = () => {
    event.emit('CLOSE_MODAL_SETTINGS');
    setSettingsOpen(false);
  };

  useEffect(() => {
    getAudioAndVideoTracks();
  }, [getAudioAndVideoTracks]);

  useEffect(() => {
    window.addEventListener('beforeunload', disconnectSession);

    return () => {
      window.removeEventListener('beforeunload', disconnectSession);
    };
  }, []);

  if (isFetching || isConnecting || isAcquiringLocalTracks) {
    return (
      <div className={classes.container} style={{ justifyContent: 'center' }}>
        <CircularProgress size={64} color="primary" />
        <Typography>
          <LoadingText />
        </Typography>
      </div>
    );
  }

  if (error?.message === 'Room completed') {
    return <ScreenErrorRoomCompleted />;
  }

  if (error?.message === 'Room contains too many Participants') {
    return <ScreenErrorRoomLimitFull />;
  }

  return (
    <div className={classes.container}>
      <Logo full size="large" />

      <div className={classes.videoContainer}>
        <LocalVideoPreview />

        <div className={classes.videoActions}>
          <div className={classes.videoControl}>
            <ToggleAudioButton disabled={disableButtons} />
            <ToggleVideoButton disabled={disableButtons} />
          </div>

          <Fab size="medium" className={classes.buttonSetting} onClick={showSettings}>
            <FontAwesomeIcon size="xl" icon={faGear} />
          </Fab>
        </div>
      </div>

      <Divider />

      <Button className={classes.button} color="primary" variant="contained" onClick={handleJoin}>
        Unirse ahora
      </Button>

      <Divider />

      <DeviceSelectionDialog open={settingsOpen} onClose={hideSettings} />
    </div>
  );
}
