import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { useMessageEventListener } from '../../hooks/useMessage/useMessageEventListener';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useEffect, useState } from 'react';
import { useAppState } from '../../state';
import meetingService from '../../services/meeting.service';
import { useRole } from '../../contexts/AppRoleContext';

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      position: 'fixed',
      left: '1rem',
      top: '1rem',
      color: theme.colors['secondary-500'],
      zIndex: 100,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    recording: {
      animation: '$recording 1s ease-in-out infinite',
      width: '16px',
      height: '16px',
      borderRadius: '50%',
      background: theme.colors['secondary-500'],
      marginRight: '0.5rem',
    },
    '@keyframes recording': {
      from: {
        opacity: 1,
      },
      to: {
        opacity: 0.2,
      },
    },
  });
});

export default function RecordingNotifications() {
  const classes = useStyles();
  const { isRecording, setIsRecording } = useVideoContext();
  const { getToken } = useAppState();
  const { room } = useVideoContext();
  const [shouldShowRecording, setShouldShowRecording] = useState(false);
  const { isAdmin, isParticipant } = useRole();
  useEffect(() => {
    async function getTokenAndCheckRecording() {
      const { recorded } = await getToken();
      await getToken().then(() => setShouldShowRecording(recorded));
    }
    getTokenAndCheckRecording();
  }, []);

  useEffect(() => {
    const recordingStatus = localStorage.getItem('isRecording');
    const isCurrentlyRecording = recordingStatus !== null && JSON.parse(recordingStatus);
    if (isRecording !== isCurrentlyRecording) {
      setIsRecording(isCurrentlyRecording);
    }
  });

  useMessageEventListener('startRecording', statusChange);
  useMessageEventListener('stopRecording', stopRecording);

  function statusChange() {
    setIsRecording(true);
    setShouldShowRecording(true);
    localStorage.setItem('isRecording', 'true');
  }
  function stopRecording() {
    if (room?.sid) {
      meetingService.updateStateRecording(room.sid);
    }
    setIsRecording(false);
    localStorage.setItem('isRecording', 'false');
    setShouldShowRecording(false);
  }
  if (isAdmin) {
    if (!shouldShowRecording || !isRecording) return null;
  }
  if (isParticipant) {
    if (!shouldShowRecording) return null;
  }
  return (
    <div className={classes.container}>
      <div className={classes.recording} />
      <span>Grabando...</span>
    </div>
  );
}
