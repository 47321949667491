import React from 'react';

import Logo from '../../Logo/Logo';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import IconLink from '../../Icons/IconLink';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: '2rem',
      height: '100%',
      width: '100%',
      margin: '0 auto',
      padding: '4rem 1rem 1rem',
      textAlign: 'center',
      maxWidth: '500px',
    },
    title: {
      color: theme.colors['error-900'],
      fontWeight: 600,
    },
    img: {
      width: '300px',
      [theme.breakpoints.up('md')]: {
        width: '100%',
      },
    },
  })
);

function MeetingInvalidUrl() {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      <Logo full size="large" />

      <IconLink />

      <Typography variant="h3" className={classes.title}>
        La url de la consulta es inválida
      </Typography>

      <Typography variant="body2">
        Por favor, verifica que la url sea correcta. Si el problema persiste, por favor, contacta a tu médico.
      </Typography>
    </div>
  );
}

export default MeetingInvalidUrl;
