import React, { useMemo } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMicrophone, faMicrophoneSlash } from '@fortawesome/pro-light-svg-icons';

import useLocalAudioToggle from '../../../hooks/useLocalAudioToggle/useLocalAudioToggle';
import { usePermissionDenied } from '../../../hooks/usePermissionDenied/usePermissionDenied';
import { createStyles, Fab, makeStyles, Tooltip, Theme, Badge } from '@material-ui/core';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
      '&:disabled': {
        background: theme.colors['neutral-500'],
        color: 'white',
        opacity: 0.5,
      },
      '&[data-is-device-error="true"]': {
        opacity: 0.5,
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        top: 10,
        right: 10,
        background: '#F9A806',
        color: 'white',
        fontWeight: 900,
      },
    },
  })
);

const MESSAGES = {
  permissionDenied: 'No es posible activar el micrófono. Habilita los permisos del navegador',
  startingAudioinputFailed: 'El micrófono no se puede activar en este momento. Por favor, revisa la conexión del mismo',
  noAudioInputDevices:
    'No es posible activar el micrófono en este momento, porque está siendo usado por otra aplicación',
};

export default function ToggleAudioButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const disabled = props.disabled;
  const { isStartingAudioinputFailed } = useVideoContext();
  const [isAudioEnabled, toggleAudioEnabled] = useLocalAudioToggle();
  const { hasAudioInputDevices } = useDevices();
  const { isPermissionDenied } = usePermissionDenied('microphone');
  const showWarning = isPermissionDenied || !hasAudioInputDevices || isStartingAudioinputFailed;

  const tooltipText = useMemo(() => {
    if (isPermissionDenied) return MESSAGES.permissionDenied;
    if (!hasAudioInputDevices) return MESSAGES.noAudioInputDevices;
    if (isStartingAudioinputFailed) return MESSAGES.startingAudioinputFailed;

    return isAudioEnabled ? 'Desactivar micrófono' : 'Activar micrófono';
  }, [isAudioEnabled, hasAudioInputDevices, isPermissionDenied, isStartingAudioinputFailed]);

  const toggleAudio = () => {
    if (!hasAudioInputDevices || isPermissionDenied || isStartingAudioinputFailed) return;

    toggleAudioEnabled();
  };

  return (
    <Tooltip title={tooltipText}>
      <Badge invisible={!showWarning} badgeContent="!" className={classes.badge}>
        <Fab
          size="medium"
          className={clsx(classes.button, props.className)}
          onClick={toggleAudio}
          disabled={disabled}
          data-is-device-error={showWarning}
          data-cy-audio-toggle
        >
          {isAudioEnabled ? (
            <FontAwesomeIcon size="xl" icon={faMicrophone} />
          ) : (
            <FontAwesomeIcon size="xl" icon={faMicrophoneSlash} />
          )}
        </Fab>
      </Badge>
    </Tooltip>
  );
}
