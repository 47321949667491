import React from 'react';

interface IconLinkProps {
  size?: number;
}

function IconLink({ size = 150 }: IconLinkProps) {
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      width={size}
      height={size}
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <g>
        <path
          style={{ fill: '#B3404A' }}
          d="M383.112,223.342c-3.162,0-6.347-1.034-9.008-3.162c-6.227-4.981-7.238-14.067-2.257-20.294
		l23.098-28.877c4.982-6.226,14.067-7.238,20.294-2.257c6.227,4.981,7.238,14.067,2.257,20.294l-23.098,28.877
		C391.545,221.487,387.348,223.342,383.112,223.342z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M435.095,270.439h-37.537c-7.973,0-14.438-6.465-14.438-14.438c0-7.973,6.465-14.438,14.438-14.438
		h37.537c7.973,0,14.438,6.465,14.438,14.438C449.533,263.973,443.069,270.439,435.095,270.439z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M406.229,346.41c-4.237,0-8.433-1.854-11.285-5.42l-23.098-28.877
		c-4.98-6.227-3.97-15.311,2.257-20.294c6.228-4.98,15.313-3.969,20.294,2.257l23.098,28.877c4.98,6.227,3.97,15.311-2.257,20.294
		C412.576,345.376,409.393,346.41,406.229,346.41z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M128.887,223.342c-4.237,0-8.433-1.854-11.285-5.42l-23.098-28.877
		c-4.98-6.227-3.97-15.311,2.257-20.294c6.228-4.981,15.311-3.97,20.294,2.257l23.098,28.877c4.98,6.227,3.97,15.311-2.257,20.294
		C135.232,222.31,132.049,223.342,128.887,223.342z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M114.44,270.439H76.904c-7.973,0-14.438-6.465-14.438-14.438c0-7.973,6.465-14.438,14.438-14.438
		h37.537c7.973,0,14.438,6.465,14.438,14.438C128.878,263.973,122.413,270.439,114.44,270.439z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M105.769,346.41c-3.162,0-6.347-1.034-9.008-3.162c-6.227-4.981-7.238-14.067-2.257-20.294
		l23.098-28.877c4.982-6.226,14.067-7.236,20.294-2.257c6.227,4.981,7.238,14.067,2.257,20.294l-23.098,28.877
		C114.202,344.553,110.004,346.41,105.769,346.41z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M471.123,176.32c-7.973,0-14.438-6.465-14.438-14.438v-53.826c0-29.082-23.661-52.741-52.741-52.741
		h-24.949c-7.973,0-14.438-6.465-14.438-14.438s6.465-14.438,14.438-14.438h24.949c45.004,0,81.617,36.613,81.617,81.617v53.826
		C485.561,169.855,479.096,176.32,471.123,176.32z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M40.877,176.32c-7.973,0-14.438-6.465-14.438-14.438v-53.826c0-45.004,36.613-81.617,81.617-81.617
		h24.948c7.973,0,14.438,6.465,14.438,14.438s-6.465,14.438-14.438,14.438h-24.948c-29.082,0-52.741,23.661-52.741,52.741v53.826
		C55.315,169.855,48.85,176.32,40.877,176.32z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M403.943,485.561h-24.948c-7.973,0-14.438-6.465-14.438-14.438c0-7.973,6.465-14.438,14.438-14.438
		h24.948c29.082,0,52.741-23.661,52.741-52.741v-53.826c0-7.973,6.465-14.438,14.438-14.438c7.973,0,14.438,6.465,14.438,14.438
		v53.826C485.561,448.947,448.947,485.561,403.943,485.561z"
        />
        <path
          style={{ fill: '#B3404A' }}
          d="M133.005,485.561h-24.948c-45.004,0-81.617-36.613-81.617-81.617v-53.826
		c0-7.973,6.465-14.438,14.438-14.438s14.438,6.465,14.438,14.438v53.826c0,29.082,23.661,52.741,52.741,52.741h24.948
		c7.973,0,14.438,6.465,14.438,14.438C147.442,479.096,140.979,485.561,133.005,485.561z"
        />
      </g>
      <path
        style={{ fill: '#EBAFAE' }}
        d="M202.039,208.49c12.894,0,23.349-10.453,23.349-23.349V91.748c0-16.593,14.019-30.612,30.612-30.612
	c16.593,0,30.612,14.019,30.612,30.612v93.395c0,12.894,10.453,23.349,23.349,23.349l0,0c12.894,0,23.349-10.453,23.349-23.349
	V91.748c0-42.521-34.789-77.31-77.31-77.31l0,0c-42.521,0-77.31,34.789-77.31,77.31v93.395
	C178.69,198.037,189.143,208.49,202.039,208.49L202.039,208.49z"
      />
      <path
        style={{ fill: '#B1404A' }}
        d="M309.961,222.928c-20.837,0-37.787-16.95-37.787-37.787V91.748c0-8.616-7.558-16.175-16.175-16.175
	c-8.616,0-16.175,7.558-16.175,16.175v93.395c0,20.837-16.95,37.787-37.787,37.787s-37.787-16.95-37.787-37.787V91.748
	C164.252,41.158,205.409,0,256,0s91.748,41.158,91.748,91.748v93.395C347.746,205.978,330.796,222.928,309.961,222.928z M256,46.698
	c24.42,0,45.05,20.63,45.05,45.05v93.395c0,4.913,3.998,8.911,8.911,8.911s8.911-3.998,8.911-8.911V91.748
	c0-34.668-28.204-62.872-62.872-62.872c-34.668,0-62.872,28.204-62.872,62.872v93.395c0,4.913,3.998,8.911,8.911,8.911
	s8.911-3.998,8.911-8.911V91.748C210.948,67.328,231.578,46.698,256,46.698z"
      />
      <path
        style={{ fill: '#EBAFAE' }}
        d="M309.96,303.51c-12.894,0-23.349,10.453-23.349,23.349v93.395c0,16.593-14.019,30.612-30.612,30.612
	c-16.593,0-30.612-14.019-30.612-30.612v-93.395c0-12.894-10.453-23.349-23.349-23.349l0,0c-12.894,0-23.349,10.453-23.349,23.349
	v93.395c0,42.521,34.789,77.31,77.31,77.31l0,0c42.521,0,77.31-34.789,77.31-77.31v-93.395
	C333.309,313.963,322.856,303.51,309.96,303.51L309.96,303.51z"
      />
      <path
        style={{ fill: '#B1404A' }}
        d="M256,512c-50.59,0-91.748-41.158-91.748-91.748v-93.395c0-20.837,16.95-37.787,37.787-37.787
	s37.787,16.95,37.787,37.787v93.395c0,8.616,7.558,16.175,16.175,16.175c8.616,0,16.175-7.558,16.175-16.175v-93.395
	c0-20.837,16.95-37.787,37.787-37.787s37.787,16.95,37.787,37.787v93.395C347.746,470.842,306.59,512,256,512z M202.039,317.947
	c-4.913,0-8.911,3.998-8.911,8.911v93.395c0,34.668,28.204,62.872,62.872,62.872c34.668,0,62.872-28.204,62.872-62.872v-93.395
	c0-4.913-3.998-8.911-8.911-8.911s-8.911,3.998-8.911,8.911v93.395c0,24.42-20.63,45.05-45.05,45.05s-45.05-20.63-45.05-45.05
	v-93.395C210.948,321.944,206.952,317.947,202.039,317.947z"
      />
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
      <g></g>
    </svg>
  );
}

export default IconLink;
