import React, { useEffect } from 'react';
import { Button, Card, Theme, Typography, makeStyles } from '@material-ui/core';

const FAVICON_ERROR = 'favicon-error.ico';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#19181B',
    height: '100vh',
    width: '100vw',
    overflow: 'hidden',
    padding: 10,
    '@media (max-width: 600px)': {
      alignItems: 'flex-start',
      marginTop: 10,
    },
  },
  card: {
    padding: 32,
    maxWidth: 500,
    borderRadius: '24px !important',
    color: theme.colors['neutral-500'],
  },
  actions: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    gap: 10,
    '& > button': {
      textTransform: 'none',
      borderRadius: 12,
      padding: '10px 20px',
    },
  },
  close: {
    color: theme.colors['primary-500'],
  },
  useHere: {
    backgroundColor: theme.colors['primary-500'],
  },
}));

const MeetingHaveActive = () => {
  const classes = useStyles();

  const handleUseThisTab = () => {
    localStorage.removeItem('active_connection');
    window.location.reload();
  };

  const handleCancel = () => {
    window.location.href = 'https://www.saludtools.com/';
  };

  const changeFavicon = (name: string) => {
    const link = document.querySelector('link[rel="icon"]');
    link && link.setAttribute('href', name);
  };

  useEffect(() => {
    changeFavicon(FAVICON_ERROR);
  }, []);

  return (
    <div className={classes.container}>
      <Card className={classes.card}>
        <Typography>
          La videoconsulta ya está abierta en otra ventana haz clic en “Usar aquí” para abrir la videoconsulta en esta
          pestaña.
        </Typography>

        <footer className={classes.actions}>
          <Button color="primary" className={classes.close} onClick={handleCancel}>
            Cerrar
          </Button>
          <Button color="primary" variant="contained" className={classes.useHere} onClick={handleUseThisTab}>
            Usar esta pestaña
          </Button>
        </footer>
      </Card>
    </div>
  );
};

export default MeetingHaveActive;
