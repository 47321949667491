import React from 'react';
import { useRole } from '../../contexts/AppRoleContext';

import MeetingConnectorForAdministrator from '../MeetingConnector/MeetingConnectorForAdministrator/MeetingConnectorForAdministrator';
import MeetingConnectorForParticipant from '../MeetingConnector/MeetingConnectorForParticipant/MeetingConnectorForParticipant';

interface MeetingStateProviderProps {
  children: React.ReactNode;
}

const MeetingStateProvider = ({ children }: MeetingStateProviderProps) => {
  const { isAdmin } = useRole();

  if (isAdmin) {
    return <MeetingConnectorForAdministrator>{children}</MeetingConnectorForAdministrator>;
  }

  return <MeetingConnectorForParticipant>{children}</MeetingConnectorForParticipant>;
};

export default MeetingStateProvider;
