import React from 'react';
import { CircularProgress, createStyles, makeStyles, Theme, Typography } from '@material-ui/core';

interface AppLoadingProps {
  text?: string;
  dark?: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      gap: 20,
      padding: 100,
      margin: '0 auto',
      textAlign: 'center',
      backgroundColor: ({ dark }: AppLoadingProps) =>
        dark ? theme.colors['neutral-600'] : theme.colors['neutral-100'],
      color: ({ dark }: AppLoadingProps) => (dark ? theme.colors['neutral-200'] : theme.colors['neutral-900']),
    },
  });
});

const AppLoading = (props: AppLoadingProps) => {
  const { text } = props;
  const classes = useStyles(props);

  return (
    <div className={classes.container}>
      <CircularProgress size={64} color="primary" />
      {text && <Typography variant="h6">{text}</Typography>}
    </div>
  );
};

export default AppLoading;
