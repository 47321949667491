import React, { useState } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhoneXmark } from '@fortawesome/pro-light-svg-icons';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import { Button, Dialog, DialogActions, DialogContent, Fab, Tooltip, Typography } from '@material-ui/core';

import meetingService from '../../../services/meeting.service';
import { useRole } from '../../../contexts/AppRoleContext';
import { useMessageEventEmitter } from '../../../hooks/useMessage/useMessageEventEmitter';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.brand,
      color: 'white',
      '&:hover': {
        background: '#600101',
      },
    },
  })
);

export default function EndCallButton(props: { className?: string }) {
  const classes = useStyles();
  const event = useMessageEventEmitter();
  const { isAdmin, isParticipant } = useRole();
  const [open, setOpen] = useState(false);

  const showModal = () => {
    isParticipant && setOpen(true);
    isAdmin && event.emit('END_MEETING');
  };

  const handleEndCall = () => {
    setOpen(false);
    meetingService.endMeeting();
  };

  return (
    <>
      <Tooltip title="Salir de la llamada">
        <span>
          <Fab size="medium" onClick={showModal} className={clsx(classes.button, props.className)} data-cy-disconnect>
            <FontAwesomeIcon size="xl" icon={faPhoneXmark} />
          </Fab>
        </span>
      </Tooltip>

      <Dialog open={open} onClose={() => setOpen(false)} maxWidth="xs">
        <DialogContent>
          <Typography variant="h4" style={{ textAlign: 'center', marginBottom: 20 }}>
            ¿Estás seguro de que quieres salir de la llamada?
          </Typography>

          <Typography variant="body2" style={{ textAlign: 'justify' }}>
            Está por terminar la videollamada, si lo hace, esta quedará finalizada de manera definitiva y usted no podrá
            continuar comunicándose con el profesional de la salud.
          </Typography>
        </DialogContent>

        <DialogActions style={{ justifyContent: 'center', padding: 10 }}>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancelar
          </Button>

          <Button onClick={handleEndCall} color="secondary" autoFocus>
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
