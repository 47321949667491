import React, { useEffect } from 'react';

import { useAppState } from '../../../state';

import AppLoading from '../../AppLoading/AppLoading';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function MeetingRoomAutoConnecting() {
  const { getToken } = useAppState();
  const { connect: chatConnect } = useChatContext();
  const { connect: videoConnect, setIsRecording, getAudioAndVideoTracks } = useVideoContext();

  const handleJoin = async () => {
    const { token } = await getToken();
    const isEnableTwilioConversation = process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true';

    token && (await videoConnect(token).then(() => setIsRecording(false)));
    isEnableTwilioConversation && chatConnect(token);
  };

  useEffect(() => {
    getAudioAndVideoTracks();
    handleJoin();
  }, []);

  return <AppLoading dark text="Conectando a la sala..." />;
}
