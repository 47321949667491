import React from 'react';
import { styled, Theme } from '@material-ui/core/styles';

import './App.css';

import MenuBar from './components/MenuBar/MenuBar';
import MeetingStateProvider from './components/MeetingStates/MeetingStateProvider';
import ReconnectingNotification from './components/ReconnectingNotification/ReconnectingNotification';
import RecordingNotifications from './components/RecordingNotifications/RecordingNotifications';
import Room from './components/Room/Room';

import useHeight from './hooks/useHeight/useHeight';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: '1fr auto',
});

const Main = styled('main')(({ theme }: { theme: Theme }) => ({
  overflow: 'hidden',
  paddingBottom: `${theme.footerHeight}px`,
  background: theme.colors['neutral-600'],
}));

export default function App() {
  const height = useHeight();

  return (
    <MeetingStateProvider>
      <Container style={{ height }}>
        <Main>
          <ReconnectingNotification />
          <RecordingNotifications />
          <Room />
          <MenuBar />
        </Main>
      </Container>
    </MeetingStateProvider>
  );
}
