import React, { useEffect, useState } from 'react';

export function useLocalStorageState<T>(key: string, initialState: T) {
  const isLocalStorageAvailable = (): boolean => {
    try {
      const test = '__test__';
      window.localStorage.setItem(test, test);
      window.localStorage.removeItem(test);
      return true;
    } catch (e) {
      return false;
    }
  };

  const localStorageSupported = isLocalStorageAvailable();

  const [storedValue, setStoredValue] = useState<T>(() => {
    if (localStorageSupported) {
      const item = window.localStorage.getItem(key);
      if (item !== null) {
        return JSON.parse(item) as T;
      }
    }
    return initialState;
  });

  const [inMemoryValue, setInMemoryValue] = useState<T>(initialState);

  useEffect(() => {
    if (localStorageSupported) {
      window.localStorage.setItem(key, JSON.stringify(storedValue));
    } else {
      setInMemoryValue(storedValue);
    }
  }, [key, storedValue, localStorageSupported]);

  return localStorageSupported
    ? ([storedValue, setStoredValue] as const)
    : ([inMemoryValue, setInMemoryValue] as const);
}
