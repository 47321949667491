import React, { useEffect, useRef, useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core';

import MeetingRoomAutoConnecting from '../../MeetingStates/MeetingRoomConnecting/MeetingRoomAutoConnecting';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import AlertWaitingForTheParticipants from '../../Alerts/WaitingForTheParticipants/AlertWaitingForTheParticipants';
import AppLoading from '../../AppLoading/AppLoading';
import { useMessageEventEmitter } from '../../../hooks/useMessage/useMessageEventEmitter';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    position: 'relative',
    height: '100%',
    width: '100%',
    backgroundColor: theme.colors['neutral-600'],
  },
}));

interface MeetingConnectorForAdministratorProps {
  children: React.ReactNode;
}

const MeetingConnectorForAdministrator = ({ children }: MeetingConnectorForAdministratorProps) => {
  const classes = useStyles();
  const [isLoadingAudioAndVideoTracks, setIsLoadingAudioAndVideoTracks] = useState(true);
  const event = useMessageEventEmitter();
  const lastRoomState = useRef<string | null>(null);
  const { room, state: roomState, getAudioAndVideoTracks } = useVideoContext();

  useEffect(() => {
    setIsLoadingAudioAndVideoTracks(true);
    getAudioAndVideoTracks().finally(() => setIsLoadingAudioAndVideoTracks(false));
  }, []);

  useEffect(() => {
    if (roomState === 'disconnected' && lastRoomState.current === 'reconnecting') {
      event.emit('RELOAD_MEETING');
    }

    lastRoomState.current = roomState;
  }, [event, roomState]);

  if (isLoadingAudioAndVideoTracks) {
    return <AppLoading dark text="Obteniendo configuración de audio y video..." />;
  }

  if (roomState === 'disconnected' && !isLoadingAudioAndVideoTracks) {
    return <MeetingRoomAutoConnecting />;
  }

  if (!room) {
    return <AppLoading dark text="Conectando a la sala..." />;
  }

  return (
    <div className={classes.container}>
      {children}
      <AlertWaitingForTheParticipants />
    </div>
  );
};

export default MeetingConnectorForAdministrator;
