import { useEffect, useState } from 'react';
import useMeetingParams from './useMeetingParams';

import meetingService from '../../services/meeting.service';

export default function useMeetingStart(immediate = true) {
  const { sessionId, participantId, typeId, companyId } = useMeetingParams();

  const [error, setError] = useState<Error>();
  const [isFetching, setIsFetching] = useState(true);
  const [startsAt, setStartsAt] = useState<Date>();

  const getMeetingStartDate = () => {
    setIsFetching(true);

    meetingService
      .getScheduledMeetingTime()
      .then(data => setStartsAt(new Date(data.payload.startDate)))
      .catch(err => setError(err))
      .finally(() => setIsFetching(false));
  };

  const startMeetingStatus = () => {
    if (!sessionId) return;

    meetingService.startMeetingStatus(sessionId);
  };

  const getTimeInMilisecondsFromInputDateMinusTenMinutes = (date: Date) => {
    const tenMinutes = 10 * 60 * 1000;

    return date.getTime() - Date.now() - tenMinutes;
  };

  useEffect(() => {
    immediate && getMeetingStartDate();

    return () => {};
  }, [sessionId, participantId, typeId, companyId]);

  useEffect(() => {
    if (!startsAt) return;

    const time = getTimeInMilisecondsFromInputDateMinusTenMinutes(startsAt);

    if (time < 0) startMeetingStatus();
    else setTimeout(startMeetingStatus, time);
  }, [startsAt]);

  return {
    error,
    startsAt,
    isFetching,
    getMeetingStartDate,
  };
}
