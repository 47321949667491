import React, { createContext, useCallback, useContext, useMemo, useReducer, useState } from 'react';
import { TwilioError } from 'twilio-video';

import { RoomType } from '../types';
import { settingsReducer, initialSettings, Settings, SettingsAction } from './settings/settingsReducer';
import { useLocalStorageState } from '../hooks/useLocalStorageState/useLocalStorageState';
import { User } from 'firebase/auth';

import useActiveSinkId from './useActiveSinkId/useActiveSinkId';
import meetingService, { GetToken, UpdateRecordingRules } from '../services/meeting.service';

export interface StateContextType {
  error: TwilioError | Error | null;
  setError(error: TwilioError | Error | null): void;
  getToken: GetToken;
  user?: User | null | { displayName: undefined; photoURL: undefined; passcode?: string };
  signIn?(passcode?: string): Promise<void>;
  signOut?(): Promise<void>;
  isAuthReady?: boolean;
  isFetching: boolean;
  activeSinkId: string;
  setActiveSinkId(sinkId: string): void;
  settings: Settings;
  dispatchSetting: React.Dispatch<SettingsAction>;
  roomType?: RoomType;
  updateRecordingRules: UpdateRecordingRules;
  isGalleryViewActive: boolean;
  setIsGalleryViewActive: React.Dispatch<React.SetStateAction<boolean>>;
  maxGalleryViewParticipants: number;
  setMaxGalleryViewParticipants: React.Dispatch<React.SetStateAction<number>>;
  isParticipantListOpen: boolean;
  setIsParticipantListOpen: React.Dispatch<React.SetStateAction<boolean>>;
  showModal: boolean;
  setshowModal: React.Dispatch<React.SetStateAction<boolean>>;
  autoCloseCountdown: number;
  setAutoCloseCountdown: React.Dispatch<React.SetStateAction<number>>;
}

export const StateContext = createContext<StateContextType>(null!);

export default function AppStateProvider(props: React.PropsWithChildren<{}>) {
  const [error, setError] = useState<TwilioError | null>(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isGalleryViewActive, setIsGalleryViewActive] = useLocalStorageState('gallery-view-active-key', true);
  const [activeSinkId, setActiveSinkId] = useActiveSinkId();
  const [settings, dispatchSetting] = useReducer(settingsReducer, initialSettings);
  const [roomType, setRoomType] = useState<RoomType>('group');
  const [maxGalleryViewParticipants, setMaxGalleryViewParticipants] = useLocalStorageState(
    'max-gallery-participants-key',
    6
  );
  const [isParticipantListOpen, setIsParticipantListOpen] = useState(false);

  const getToken: StateContextType['getToken'] = useCallback(() => {
    setIsFetching(true);

    return meetingService
      .getToken()
      .then(res => {
        setRoomType(res.room_type);
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        return Promise.reject(err);
      })
      .finally(() => setIsFetching(false));
  }, []);

  const updateRecordingRules: StateContextType['updateRecordingRules'] = useCallback((room_sid, rules) => {
    setIsFetching(true);
    return meetingService
      .updateRecordingRules(room_sid, rules)
      .then(res => {
        setIsFetching(false);
        return res;
      })
      .catch(err => {
        setError(err);
        setIsFetching(false);
        return Promise.reject(err);
      });
  }, []);

  const contextValue = useMemo(
    () =>
      ({
        error,
        setError,
        isFetching,
        activeSinkId,
        setActiveSinkId,
        settings,
        dispatchSetting,
        roomType,
        isGalleryViewActive,
        setIsGalleryViewActive,
        maxGalleryViewParticipants,
        setMaxGalleryViewParticipants,
        updateRecordingRules,
        getToken,
        isParticipantListOpen,
        setIsParticipantListOpen,
      } as StateContextType),
    [
      error,
      setError,
      isFetching,
      activeSinkId,
      setActiveSinkId,
      settings,
      dispatchSetting,
      roomType,
      isGalleryViewActive,
      setIsGalleryViewActive,
      maxGalleryViewParticipants,
      setMaxGalleryViewParticipants,
      updateRecordingRules,
      getToken,
      isParticipantListOpen,
      setIsParticipantListOpen,
    ]
  );

  return <StateContext.Provider value={contextValue}>{props.children}</StateContext.Provider>;
}

export function useAppState() {
  const context = useContext(StateContext);
  if (!context) {
    throw new Error('useAppState must be used within the AppStateProvider');
  }
  return context;
}
