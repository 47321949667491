import React, { useEffect } from 'react';

import Logo from '../../Logo/Logo';
import SurveySatisfaction from '../../SurveySatisfaction/SurveySatisfaction';
import { Typography } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: '2rem',
      gap: '2rem',
      height: '100vh',
      width: '100%',
      margin: '0 auto',
      textAlign: 'center',
      maxWidth: '500px',
    },
    title: {
      color: theme.colors['neutral-900'],
    },
    img: {
      height: '40vh',
      [theme.breakpoints.up('md')]: {
        height: '60vh',
      },
    },
  })
);

function MeetingEnded() {
  const classes = useStyles();
  const { videoStream, audioStream } = useVideoContext();
  useEffect(() => {
    videoStream?.getTracks().forEach(track => track.stop());
    audioStream?.getTracks().forEach(track => track.stop);
  }, []);
  return (
    <>
      <div className={classes.container}>
        <Logo full size="large" />

        <Typography variant="h2" className={classes.title}>
          Tu consulta ha finalizado
        </Typography>

        <Typography variant="h4">Gracias por confiar en nosotros</Typography>

        <img className={classes.img} src={require('../../../assets/meeting-ended.png')} alt="" />
      </div>

      <SurveySatisfaction />
    </>
  );
}

export default MeetingEnded;
