import { createTheme } from '@material-ui/core';

declare module '@material-ui/core/styles/createTheme' {
  interface Theme {
    sidebarWidth: number;
    sidebarMobileHeight: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth: number;
    galleryViewBackgroundColor: string;
    colors: Record<string, string>;
  }

  interface ThemeOptions {
    sidebarWidth?: number;
    sidebarMobileHeight?: number;
    brand: string;
    footerHeight: number;
    mobileTopBarHeight: number;
    mobileFooterHeight: number;
    sidebarMobilePadding: number;
    participantBorderWidth: number;
    rightDrawerWidth?: number;
    galleryViewBackgroundColor: string;
    colors: Record<string, string>;
  }
}

export default createTheme({
  overrides: {
    MuiCssBaseline: {
      '@global': {
        'html, body, #root': {
          height: '100%',
          color: '#606060',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Poppins, sans-serif',
    fontSize: 14,
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: '1.25rem',
      lineHeight: 1.2,
    },
  },
  palette: {
    primary: {
      main: '#1C6AB9',
    },
    secondary: {
      main: '#ED2626',
    },
  },
  brand: '#E22525',
  footerHeight: 72,
  mobileFooterHeight: 56,
  sidebarWidth: 366,
  sidebarMobileHeight: 90,
  sidebarMobilePadding: 8,
  participantBorderWidth: 2,
  mobileTopBarHeight: 52,
  rightDrawerWidth: 380,
  galleryViewBackgroundColor: '#121C2D',
  colors: {
    inherit: 'inherit',
    transparent: 'transparent',
    black: '#000',
    white: '#fff',
    'primary-900': '#164062',
    'primary-800': '#1E4E76',
    'primary-700': '#2E6B9E',
    'primary-600': '#1C6AB9',
    'primary-500': '#0080EA',
    'primary-400': '#01A3FF',
    'primary-300': '#E9F3FD',
    'primary-200': '#F1F6FF',
    'primary-100': '#F6FAFF',
    'secondary-900': '#A70303',
    'secondary-800': '#C00202',
    'secondary-700': '#D52516',
    'secondary-600': '#ED2626',
    'secondary-500': '#FF3939',
    'secondary-400': '#FF5349',
    'secondary-300': '#F8C9C9',
    'secondary-200': '#FBDDDB',
    'secondary-100': '#FDEEED',
    'neutral-900': '#000',
    'neutral-800': '#000',
    'neutral-700': '#0A0A0A',
    'neutral-600': '#19181B',
    'neutral-500': '#606060',
    'neutral-400': '#D6D9DB',
    'neutral-300': '#DBD6D6',
    'neutral-200': '#F4F4F4',
    'neutral-100': '#FBFBFB',
    'success-900': '#29644E',
    'success-800': '#317159',
    'success-700': '#2C7E66',
    'success-600': '#268B72',
    'success-500': '#1BA58A',
    'success-400': '#1BBC9B',
    'success-300': '#47C8AC',
    'success-200': '#73D4BD',
    'success-100': '#CBEBDF',
    'error-900': '#9B0000',
    'error-800': '#AD0B0B',
    'error-700': '#BE1515',
    'error-600': '#C72020',
    'error-500': '#D02A2A',
    'error-400': '#E24D4D',
    'error-300': '#E97171',
    'error-200': '#EF9594',
    'error-100': '#FBDDDB',
    'warning-900': '#6E4600',
    'warning-800': '#7F5000',
    'warning-700': '#8E5B02',
    'warning-600': '#A26700',
    'warning-500': '#AF740C',
    'warning-400': '#EFB848',
    'warning-300': '#F4C95D',
    'warning-200': '#FAE4AE',
    'warning-100': '#FDF2D7',
    'info-900': '#0E479B',
    'info-800': '#1753AB',
    'info-700': '#205EBA',
    'info-600': '#2E6FD0',
    'info-500': '#408CFF',
    'info-400': '#5EA5FE',
    'info-300': '#AFD2FF',
    'info-200': '#D7E9FF',
    'info-100': '#EBF4FF',
  },
});
