import React, { useState, useRef } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEllipsisStrokeVertical,
  faFaceViewfinder,
  faGear,
  faImage,
  faUsersViewfinder,
} from '@fortawesome/pro-light-svg-icons';

import {
  styled,
  Theme,
  useMediaQuery,
  Menu as MenuContainer,
  MenuItem,
  Typography,
  Fab,
  makeStyles,
  createStyles,
  Tooltip,
} from '@material-ui/core';
import { isSupported } from '@twilio/video-processors';

import { useAppState } from '../../../state';
import useChatContext from '../../../hooks/useChatContext/useChatContext';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import DeviceSelectionDialog from '../../DeviceSelectionDialog/DeviceSelectionDialog';
import { useMessageEventEmitter } from '../../../hooks/useMessage/useMessageEventEmitter';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
    },
  })
);

export default function Menu(props: { buttonClassName?: string }) {
  const classes = useStyles();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const event = useMessageEventEmitter();

  const [menuOpen, setMenuOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);

  const { setIsGalleryViewActive, isGalleryViewActive } = useAppState();
  const { setIsChatWindowOpen } = useChatContext();
  const { openBackgroundSelection } = useVideoContext();

  const anchorRef = useRef<HTMLButtonElement>(null);

  const showSettings = () => {
    event.emit('OPEN_MODAL_SETTINGS');
    setSettingsOpen(true);
  };

  const hideSettings = () => {
    event.emit('CLOSE_MODAL_SETTINGS');
    setSettingsOpen(false);
    setMenuOpen(false);
  };

  return (
    <>
      <Tooltip title="Más opciones">
        <Fab
          size="medium"
          onClick={() => setMenuOpen(isOpen => !isOpen)}
          ref={anchorRef}
          className={clsx(classes.button, props.buttonClassName)}
          data-cy-more-button
        >
          <FontAwesomeIcon size="xl" icon={faEllipsisStrokeVertical} />
        </Fab>
      </Tooltip>

      <MenuContainer
        open={menuOpen}
        onClose={() => setMenuOpen(isOpen => !isOpen)}
        anchorEl={anchorRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: isMobile ? -55 : 'bottom',
          horizontal: 'center',
        }}
      >
        <MenuItem onClick={showSettings}>
          <IconContainer>
            <FontAwesomeIcon size="lg" icon={faGear} />
          </IconContainer>
          <Typography variant="body1">Configuración de audio y video</Typography>
        </MenuItem>

        {isSupported && (
          <MenuItem
            onClick={() => {
              openBackgroundSelection();
              setIsChatWindowOpen(false);
              setMenuOpen(false);
            }}
          >
            <IconContainer>
              <FontAwesomeIcon size="lg" icon={faImage} />
            </IconContainer>
            <Typography variant="body1">Efectos visuales</Typography>
          </MenuItem>
        )}

        <MenuItem
          onClick={() => {
            setIsGalleryViewActive(isGallery => !isGallery);
            setMenuOpen(false);
          }}
        >
          <IconContainer>
            {isGalleryViewActive ? (
              <FontAwesomeIcon size="lg" icon={faFaceViewfinder} />
            ) : (
              <FontAwesomeIcon size="lg" icon={faUsersViewfinder} />
            )}
          </IconContainer>
          <Typography variant="body1">{isGalleryViewActive ? 'Vista de Speaker' : 'Vista de Galería'}</Typography>
        </MenuItem>
      </MenuContainer>

      <DeviceSelectionDialog open={settingsOpen} onClose={hideSettings} />
    </>
  );
}
