import { makeStyles } from '@material-ui/core/styles';
import { Participant } from 'twilio-video';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWifiExclamation } from '@fortawesome/pro-light-svg-icons';

const useStyles = makeStyles({
  container: {
    width: 'max-content',
    height: '48px',
    padding: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '0.5em',
    background: 'rgba(255, 255, 255, 0.6)',
    color: '#19181B',
    fontWeight: 400,
    fontSize: '18px',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    borderRadius: 12,
    zIndex: 9,
  },
});

export default function NetworkQualityLevel({ participant }: { participant: Participant }) {
  const classes = useStyles();
  const networkQualityLevel = useParticipantNetworkQualityLevel(participant);

  if (Number(networkQualityLevel) >= 3 || networkQualityLevel === null) return null;

  return (
    <div className={classes.container}>
      <FontAwesomeIcon size="xl" icon={faWifiExclamation} />
      <span>Internet inestable</span>
    </div>
  );
}
