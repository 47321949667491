import React, { useCallback, useEffect, useState } from 'react';
import { isPermissionDenied } from '../../utils';

export function usePermissionDenied(name: 'camera' | 'microphone'): { isPermissionDenied: boolean } {
  const [isDenied, setIsDenied] = useState(false);

  const retrievePermissionDenied = useCallback(async () => {
    setIsDenied(await isPermissionDenied(name));
  }, [setIsDenied, name]);

  useEffect(() => {
    retrievePermissionDenied();
  }, [retrievePermissionDenied]);

  return {
    isPermissionDenied: isDenied,
  };
}
