import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import clsx from 'clsx';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVideo, faVideoSlash } from '@fortawesome/pro-light-svg-icons';

import useLocalVideoToggle from '../../../hooks/useLocalVideoToggle/useLocalVideoToggle';
import { usePermissionDenied } from '../../../hooks/usePermissionDenied/usePermissionDenied';
import { createStyles, Fab, makeStyles, Tooltip, Theme, Badge } from '@material-ui/core';
import useDevices from '../../../hooks/useDevices/useDevices';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      background: theme.colors['neutral-500'],
      color: 'white',
      '&:hover': {
        background: '#3e3e3e',
      },
      '&:disabled': {
        background: theme.colors['neutral-500'],
        color: 'white',
        opacity: 0.5,
      },
      '&[data-is-device-error="true"]': {
        opacity: 0.5,
      },
    },
    badge: {
      '& .MuiBadge-badge': {
        top: 10,
        right: 10,
        background: '#F9A806',
        color: 'white',
        fontWeight: 900,
      },
    },
  })
);

const MESSAGES = {
  permissionDenied: 'No es posible activar la cámara. Habilita los permisos del navegador',
  noVideoInputDevices: 'La cámara no se puede activar en este momento. Por favor, revisa la conexión de la misma',
  startingVideoinputFailed:
    'No es posible activar la cámara en este momento, porque está siendo usada por otra aplicación',
};

export default function ToggleVideoButton(props: { disabled?: boolean; className?: string }) {
  const classes = useStyles();
  const disabled = props.disabled;
  const lastClickTimeRef = useRef(0);
  const { setVideoStream, videoStream, isStartingVideoinputFailed, setIsStartingVideoinputFailed } = useVideoContext();

  const [isVideoEnabled, toggleVideoEnabled] = useLocalVideoToggle();
  const { hasVideoInputDevices } = useDevices();
  const { isPermissionDenied } = usePermissionDenied('camera');
  const showWarning = isPermissionDenied || !hasVideoInputDevices || isStartingVideoinputFailed;

  const tooltipText = useMemo(() => {
    if (isPermissionDenied) return MESSAGES.permissionDenied;
    if (!hasVideoInputDevices) return MESSAGES.noVideoInputDevices;
    if (isStartingVideoinputFailed) return MESSAGES.startingVideoinputFailed;

    return isVideoEnabled ? 'Desactivar cámara' : 'Activar cámara';
  }, [isVideoEnabled, hasVideoInputDevices, isPermissionDenied, isStartingVideoinputFailed]);

  useEffect(() => {
    if (showWarning && isVideoEnabled) {
      toggleVideoEnabled();
    }
    const handleDeviceChange = async () => {
      try {
        if (isStartingVideoinputFailed === true) {
          videoStream?.getTracks().forEach(track => track.stop());
          await navigator.mediaDevices.getUserMedia({ video: true }).then(stream => setVideoStream(stream));
          setIsStartingVideoinputFailed(false);
        }
      } catch (error) {
        setIsStartingVideoinputFailed(true);
      }
    };

    handleDeviceChange();
  }, [isVideoEnabled, showWarning, toggleVideoEnabled, hasVideoInputDevices, setIsStartingVideoinputFailed]);

  const toggleVideo = useCallback(() => {
    if (!hasVideoInputDevices || isPermissionDenied || isStartingVideoinputFailed) return;

    if (Date.now() - lastClickTimeRef.current > 500) {
      lastClickTimeRef.current = Date.now();
      toggleVideoEnabled();
    }
  }, [hasVideoInputDevices, isPermissionDenied, isStartingVideoinputFailed, toggleVideoEnabled]);

  return (
    <Tooltip title={tooltipText}>
      <Badge invisible={!showWarning} badgeContent="!" className={classes.badge}>
        <Fab
          id="button-toggle-video"
          size="medium"
          className={clsx(classes.button, props.className)}
          onClick={toggleVideo}
          disabled={disabled}
          data-is-device-error={showWarning}
          data-is-video-enabled={isVideoEnabled}
        >
          <FontAwesomeIcon size="xl" icon={isVideoEnabled ? faVideo : faVideoSlash} />
        </Fab>
      </Badge>
    </Tooltip>
  );
}
