import { RecordingRules, Role, RoomType } from '../types';

export type GetToken = () => Promise<{ room_type: RoomType; token: string; recorded: boolean }>;
export type GetRole = () => Promise<Role>;
export type UpdateRecordingRules = (room_sid: string, rules: RecordingRules) => Promise<any>;

const API_URL = process.env.REACT_APP_API_URL;

const getMeetingParams = () => {
  const params = new URLSearchParams(window.location.search);
  if (params.get('token')) {
    return {
      token: params.get('token'),
      recorded: Boolean(params.get('recorded')),
      sid: params.get('sid'),
    };
  }

  return {
    sessionId: params.get('s'),
    participantId: params.get('i'),
    typeId: params.get('t'),
    companyId: params.get('c'),
  };
};

const getToken: GetToken = () => {
  const endpoint = API_URL + '/ext-api/mobile/chiper/patient/twilio/checkin';
  const dataLastConnection = JSON.parse(localStorage.getItem('data_last_connection') || '{}');
  const params = getMeetingParams();
  const room_type = 'group';
  if (params.token) {
    return Promise.resolve({ room_type, ...params });
  }

  if (
    dataLastConnection?.context?.token &&
    dataLastConnection?.params?.sessionId === params?.sessionId &&
    dataLastConnection?.params?.participantId === params?.participantId &&
    dataLastConnection?.params?.typeId === params?.typeId &&
    dataLastConnection?.params?.companyId === params?.companyId
  ) {
    const token = dataLastConnection?.context?.token;
    const recorded = !!dataLastConnection?.context?.recorded;
    return Promise.resolve({ room_type, token, recorded });
  }

  localStorage.removeItem('data_last_connection');

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'content-type': 'application/json',
    },
    body: JSON.stringify(params),
  })
    .then(res => res.json())
    .then(({ payload }) => {
      const token = payload.token;
      const recorded = !!payload.recorded;
      const dataConnection = { context: { token, room_type, recorded }, params };
      localStorage.setItem('data_last_connection', JSON.stringify(dataConnection));
      return { token, room_type, recorded };
    });
};

const updateRecordingRules: UpdateRecordingRules = async (room_sid: string, rules: RecordingRules) => {
  const endpoint = API_URL + '/ext-api/mobile/chiper/patient/twilio/recordingrules';

  return fetch(endpoint, {
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ room_sid, rules }),
    method: 'POST',
  }).then(async res => {
    const jsonResponse = await res.json();

    if (!res.ok) {
      const recordingError = new Error(jsonResponse.error?.message || 'There was an error updating recording rules');
      recordingError.code = jsonResponse.error?.code;
      return Promise.reject(recordingError);
    }

    return jsonResponse;
  });
};

const getScheduledMeetingTime = () => {
  const endpoint = API_URL + '/ext-api/mobile/chiper/patient/twilio/getTimeToDate';

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(getMeetingParams()),
  }).then(res => res.json());
};

const endMeeting = () => {
  const endpoint = API_URL + '/ext-api/mobile/chiper/patient/endAppointment';

  return fetch(endpoint, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(getMeetingParams()),
  });
};

const startMeetingStatus = (sessionId: string) => {
  const endpoint = API_URL + '/ext-api/mobile/chiper/change/state/startWaitingRoom/' + sessionId;

  return fetch(endpoint, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getUrlSurveySatisfaction = (companyId: string): Promise<string> => {
  const code = 'COMPANY_SURVEY_SATISFACTION';
  const endpoint =
    API_URL + '/ext-api/mobile/chiper/company/surveySatisfaction?companyId=' + companyId + '&code=' + code;

  return fetch(endpoint, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
    .then(res => res.json())
    .then(({ value }) => value);
};

const updateState = (sessionId: string, uuid?: string) => {
  return fetch(API_URL + '/ext-api/mobile/chiper/change/state/session/' + sessionId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uuid),
  });
};
const updateStatePending = (sessionId: string, uuid: string) => {
  return fetch(API_URL + '/ext-api/mobile/chiper/change/state/pending/' + sessionId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(uuid),
    keepalive: true,
  });
};

const validatePortalPatient = async (sessionId: string) => {
  const res = await fetch(API_URL + '/ext-api/mobile/chiper/validate/activated/portalpatient/' + sessionId, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  });
  return await res.json();
};

const updateStateRecording = (sessionId: string) => {
  return fetch(API_URL + '/ext-api/mobile/chiper/change/state/recording/' + sessionId, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

export const meetingService = {
  getToken,
  updateRecordingRules,
  getScheduledMeetingTime,
  endMeeting,
  getMeetingParams,
  startMeetingStatus,
  getUrlSurveySatisfaction,
  updateState,
  updateStatePending,
  validatePortalPatient,
  updateStateRecording,
};

export default meetingService;
