import { useEffect, useState } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';

import useVideoContext from '../useVideoContext/useVideoContext';
import useMeetingParams from './useMeetingParams';

const database = getDatabase();

type MeetingState = 'CALL_ENDED' | 'CALL_IN_PROGRESS' | 'WAITING_ROOM' | undefined;

export default function useMeetingState() {
  const { room, removeLocalVideoTrack, removeLocalAudioTrack } = useVideoContext();
  const { sessionId, participantId, typeId, companyId } = useMeetingParams();

  const [state, setState] = useState<MeetingState>();
  const [isFetching, setIsFetching] = useState(false);

  const getMeetingState = () => {
    setIsFetching(true);
    const path = `chiper/s_${sessionId}/i_${participantId}/t_${typeId}/c_${companyId}`;
    const query = ref(database, path);

    return onValue(query, snapshot => {
      const data = snapshot.val();

      setState(data?.view);
      setIsFetching(false);
    });
  };

  const roomDisconnect = () => {
    if (!['CALL_ENDED', 'WAITING_ROOM'].includes(state || '')) return;
    removeLocalVideoTrack();
    removeLocalAudioTrack();

    room?.disconnect();
  };

  useEffect(getMeetingState, [companyId, participantId, sessionId, typeId, room]);
  useEffect(roomDisconnect, [state, room, removeLocalVideoTrack, removeLocalAudioTrack]);

  return {
    state,
    isFetching,
    getMeetingState,
  };
}
