import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import { Typography, Grid, Hidden } from '@material-ui/core';

import Logo from '../Logo/Logo';
import Menu from './Menu/Menu';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import ToggleParticipantListButton from '../Buttons/ToggleParticipantListButton/ToggleParticipantListButton';
import { isMobile } from '.././../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.colors['neutral-600'],
      color: 'white',
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0.75em 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: '0 0.75em',
        bottom: 4,
      },
    },
    logo: {
      display: 'none',
      [theme.breakpoints.down('sm')]: {
        '& svg': {
          display: 'none',
        },
      },
      [theme.breakpoints.up(520)]: {
        display: 'flex',
        flex: 1,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      color: 'white',
      background: 'rgba(0, 0, 0, 0.5)',
      '& button': {
        color: 'white',
        background: theme.colors['primary-600'],
        borderRaduis: '6px',
        margin: '0 2em',
        '&:hover': {
          background: theme.colors['primary-700'],
        },
      },
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const roomState = useRoomState();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const isReconnecting = roomState === 'reconnecting';

  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="body2">Estás compartiendo tu pantalla</Typography>
          <Button size="small" variant="contained" onClick={() => toggleScreenShare()}>
            Deja de compartir
          </Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around">
          <Grid className={classes.logo}>
            <Logo dark size="medium" />
          </Grid>

          <Grid item>
            <Grid container justifyContent="center" style={{ gap: '4px' }}>
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              <EndCallButton />
              {!isMobile && !isSharingScreen && <ToggleScreenShareButton disabled={isReconnecting} />}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
            </Grid>
          </Grid>

          <Grid style={{ flex: 1 }}>
            <Grid container justifyContent="flex-end" style={{ gap: '4px' }}>
              <ToggleParticipantListButton />
              <Menu />
            </Grid>
          </Grid>
        </Grid>
      </footer>
    </>
  );
}
