import React from 'react';

import AudioInputList from './AudioInputList/AudioInputList';
import AudioOutputList from './AudioOutputList/AudioOutputList';
import { DialogContent, Typography, Dialog, DialogActions, Button, Theme, DialogTitle } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import VideoInputList from './VideoInputList/VideoInputList';

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width: '600px',
    minHeight: '350px',
    [theme.breakpoints.down('xs')]: {
      width: 'calc(100vw - 32px)',
    },
    '& .inputSelect': {
      width: 'calc(100% - 35px)',
    },
  },
  buttonCancel: {
    float: 'right',
    color: theme.colors['primary-500'],
    textTransform: 'none',
    borderRadius: '8px',
    padding: '8px 16px',
    '&:hover': {
      color: theme.colors['primary-600'],
    },
  },
  buttonSave: {
    float: 'right',
    background: theme.colors['primary-500'],
    color: 'white',
    textTransform: 'none',
    borderRadius: '8px',
    padding: '8px 16px',
    '&:hover': {
      background: theme.colors['primary-600'],
    },
  },
  paper: {
    width: '100%',
    maxWidth: '600px',
    borderRadius: '12px',
    [theme.breakpoints.down('xs')]: {
      margin: '16px',
    },
  },
  headline: {
    marginBottom: '1.3em',
    fontSize: '1.1rem',
  },
  listSection: {
    margin: '2em 0 0.8em',
    '&:first-child': {
      margin: '1em 0 2em 0',
    },
  },
  content: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    gap: '1em',
    [theme.breakpoints.down('sm')]: {
      gridTemplateColumns: '1fr',
    },
  },
}));

export default function DeviceSelectionDialog({ open, onClose }: { open: boolean; onClose: () => void }) {
  const classes = useStyles();

  return (
    <Dialog open={open} onClose={onClose} classes={{ paper: classes.paper }}>
      <DialogTitle>Comprobar audio y video</DialogTitle>

      <DialogContent className={classes.container}>
        <Typography variant="body2">
          Verifica que el audio y video de tu dispositivo estén funcionando correctamente.
        </Typography>

        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Video
          </Typography>
          <VideoInputList />
        </div>

        <div className={classes.listSection}>
          <Typography variant="h6" className={classes.headline}>
            Audio
          </Typography>
          <AudioInputList />
        </div>

        <div className={classes.listSection}>
          <AudioOutputList />
        </div>
      </DialogContent>

      <DialogActions style={{ padding: '20px' }}>
        <Button onClick={onClose} color="primary" className={classes.buttonCancel} variant="text">
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  );
}
