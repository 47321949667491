import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleInfo, faInfoCircle, faXmark } from '@fortawesome/pro-light-svg-icons';
import { Button, Fab, Theme, Typography, makeStyles } from '@material-ui/core';

import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { useMessageEventEmitter } from '../../../hooks/useMessage/useMessageEventEmitter';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    position: 'absolute',
    top: 16,
    right: 16,
    borderRadius: 12,
    zIndex: 10,
    backgroundColor: theme.colors['info-500'],
    color: 'white',
    '&:hover': {
      background: theme.colors['info-600'],
    },
  },
  container: {
    display: 'grid',
    position: 'absolute',
    gridTemplateColumns: '32px 1fr',
    alignItems: 'center',
    fontSize: '12px !important',
    backgroundColor: theme.colors['primary-200'],
    width: '100%',
    maxWidth: 600,
    borderRadius: 12,
    padding: 16,
    bottom: 100,
    gap: 16,
    left: '50%',
    transform: 'translateX(-50%)',
    zIndex: 10,
    [theme.breakpoints.down('xs')]: {
      gridTemplateColumns: '24px 1fr',
    },
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'calc(100% - 8px)',
      bottom: 72,
    },
  },
  icon: {
    width: 32,
    height: 32,
    borderRadius: 12,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.colors['info-400'],
    color: 'white',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    color: theme.colors['primary-700'],
    gap: 20,
    [theme.breakpoints.down('xs')]: {
      gap: 10,
    },
  },
  close: {
    position: 'absolute',
    top: 16,
    right: 16,
    width: 20,
    height: 20,
    maxWidth: 20,
    maxHeight: 20,
    minWidth: 20,
    minHeight: 20,
    borderRadius: 999,
    zIndex: 10,
    border: `1px solid ${theme.colors['info-500']}`,
    color: theme.colors['info-500'],
  },
}));

const AlertWaitingForTheParticipants = () => {
  const classes = useStyles();
  const participants = useParticipants();
  const event = useMessageEventEmitter();
  const [showAlert, setShowAlert] = useState(false);

  function show() {
    setShowAlert(true);
    event.emit('OPEN_ALERT_WAITING_FOR_THE_PARTICIPANTS');
  }

  function close() {
    setShowAlert(false);
    event.emit('CLOSE_ALERT_WAITING_FOR_THE_PARTICIPANTS');
  }

  function toggle() {
    if (!showAlert) show();
    else close();
  }

  useEffect(() => {
    document.body.clientWidth >= 420 && show();
  }, []);

  if (participants.length > 0) return null;

  return (
    <>
      {!showAlert && (
        <Fab size="small" className={classes.button} onClick={() => toggle()}>
          <FontAwesomeIcon size="xl" icon={faCircleInfo} />
        </Fab>
      )}

      {showAlert && (
        <div className={classes.container}>
          <div className={classes.icon}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </div>

          <div className={classes.content}>
            <Typography variant="body2">Esperando que se unan los demás participantes.</Typography>
            <Typography variant="body2">
              Por favor no repitas el proceso para crear una atención, ya que generará una sala nueva. Reinvita al
              participante usando el botón “Volver a invitar” en la parte superior.
            </Typography>
          </div>

          <Button size="small" className={classes.close} onClick={() => close()}>
            <FontAwesomeIcon icon={faXmark} />
          </Button>
        </div>
      )}
    </>
  );
};

export default AlertWaitingForTheParticipants;
