import { useState, useCallback, useRef } from 'react';
import { LogLevels, Track, Room } from 'twilio-video';
import { ErrorCallback } from '../../../types';

interface MediaStreamTrackPublishOptions {
  name?: string;
  priority: Track.Priority;
  logLevel: LogLevels;
}

export default function useScreenShareToggle(room: Room | null, onError: ErrorCallback) {
  const [isSharing, setIsSharing] = useState(false);
  const stopScreenShareRef = useRef<Array<() => void>>([]);

  const shareScreen = useCallback(() => {
    navigator.mediaDevices
      .getDisplayMedia({
        audio: true,
        video: true,
      })
      .then(stream => {
        const tracks = stream.getTracks();

        tracks.forEach(track => {
          const trackName = track.kind === 'video' ? 'screen' : 'screen-audio';

          room!.localParticipant
            .publishTrack(track, { name: trackName, priority: 'low' } as MediaStreamTrackPublishOptions)
            .then(trackPublication => {
              const stopScreenShare = () => {
                room!.localParticipant.unpublishTrack(track);
                room!.localParticipant.emit('trackUnpublished', trackPublication);
                track.stop();
                setIsSharing(false);
              };

              stopScreenShareRef.current.push(stopScreenShare);
              track.onended = stopScreenShare;
              setIsSharing(true);
            })
            .catch(onError);
        });
      })
      .catch(error => {
        // Don't display an error if the user closes the screen share dialog
        if (
          error.message === 'Permission denied by system' ||
          (error.name !== 'AbortError' && error.name !== 'NotAllowedError')
        ) {
          console.error(error);
          onError(error);
        }
      });
  }, [room, onError]);

  const toggleScreenShare = useCallback(() => {
    if (room) {
      !isSharing ? shareScreen() : stopScreenShareRef.current.forEach(stopScreenShare => stopScreenShare());
    }
  }, [isSharing, shareScreen, room]);

  return [isSharing, toggleScreenShare] as const;
}
